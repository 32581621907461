import { createMuiTheme } from '@material-ui/core/styles'
// import { purple } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#303F51',
    },
    secondary: {
      main: '#2093E6',
    },
    success: {
      main: '#00ab66',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
  }
})
export default theme
