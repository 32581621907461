import { Container, Paper } from "@material-ui/core";
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import UnitForm from "../UnitDetails/UnitForm/UnitForm";

export default function AddEditUnit({ unit }) {
  return (
    <Container>
      <Paper>
        <UnitForm unit={unit} />
      </Paper>
    </Container>
  );
}
