import React from 'react'
import styles from './DeleteDialog.module.scss'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Box,
} from '@material-ui/core'
import { WarningRounded } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
export default function DeleteDialog(props) {

  const { t } = useTranslation()
  const { open, onDialogClose, onDelete } = props
  return (
    <Dialog open={open} onClose={onDialogClose} maxWidth='xl'>
      <Box className={styles.dialogWrapper}>
        <div className={styles.dialogHeader}>
          <WarningRounded className={styles.warningIcon} color='primary' />
        </div>
        <DialogContent className={styles.dialogContent}>
          <Typography variant='body1'>{t('deleteWarning')}</Typography>
          <Typography variant='body2' className={styles.secondaryText} >{t('deleteSubWarning')}</Typography>
        </DialogContent>
        <Box className={styles.dialogActions}>
          <Button className={styles.deleteButton} onClick={onDelete} id='confirm-delete'>
          {t('delete')}
        </Button>
          <Button onClick={onDialogClose} className={styles.cancelButton}>{t('Actions.cancel')}</Button>
        </Box>
      </Box>
    </Dialog>
  )
}
