/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { store } from "../../../../../../store";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  Box,
  Divider,
  Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import condeoApi from "../../../../../../common/condeoApi";
import notificationAction from "../../../../../../store/actions/notificationAction";
import styles from "./UnitForm.module.scss";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import emptyIcon from "../../../../../../assets/Images/real-estate.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
  },
  grid: {
    flexGrow: 1,
  },
  container: {
    maxHeight: 450,
  },
  formContainer: {
    width: "100%",
    height: "43em",
    padding: "30px",
    marginTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  textFieldMargin: {
    marginTop: "20px",
  },
  icon: {
    minWidth: "30px",
  },
  formControl: {
    width: "100%",
    marginTop: "20px",
  },
  grayText: {
    color: "darkgrey",
  },
  rightGrid: {
    maxWidth: "50%",
    marginRight: "30px",
  },
}));
export default function UnitForm({ onEditModeSwitch, unit, id }) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [properties, setProperties] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const { t } = useTranslation();
  const propertyId = localStorage.getItem("propertyId");
  const { user } = state;
  const { token } = state.auth;
  const history = useHistory();
  const currentPath = history.location.pathname;

  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchProperties = async () => {
    condeoApi
      .fetchProperties(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setProperties(
                response.properties.filter(
                  (p) => p.organizationId === user?.organization?.id
                )
              );
              setSelectedProperty(
                response.properties.find((p) => {
                  if (propertyId && propertyId !== "undefined") {
                    return p.id === Number(propertyId);
                  } else {
                    return p.id === unit?.propertyId;
                  }
                }) || null
              );
            }
          });
        } else {
          console.log(
            "Something went wrong fetching properties for this unit."
          );
          dispatch(
            notificationAction({
              open: true,
              message:
                "Something went wrong fetching properties for this unit.",
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.label) {
      errors.label = "This field is Required";
    }
    if (!values.description) {
      errors.description = "This field is Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      label: unit?.label ? unit.label : "",
      description: unit?.description ? unit.description : "",
      remarks: unit?.remarks ? unit.remarks : "",
      rooms: unit?.rooms ? unit.rooms : "",
      netRent: unit?.netRent ? unit.netRent : "",
      livingSpace: unit?.livingSpace ? unit.livingSpace : "",
      floorSpace: unit?.floorSpace ? unit.floorSpace : "",
      floor: unit?.floor ? unit.floor : "",
      utilitiesRent: unit?.utilitiesRent ? unit.utilitiesRent : "",
      propertyId: unit?.propertyId ? unit.propertyId : null,
    },
    validate,
    onSubmit: (values) => {
      updateUnit(values);
    },
  });
  async function updateUnit(values) {
    const model = {
      label: values.label,
      description: values.description,
      remarks: values.remarks || null,
      rooms: values.rooms || null,
      netRent: values.netRent || null,
      livingSpace: values.livingSpace || null,
      floorSpace: values.floorSpace || null,
      floor: values.floor || null,
      utilitiesRent: values.utilitiesRent || null,
      propertyId: selectedProperty?.id || unit?.propertyId || propertyId,
    };

    if (!selectedProperty) {
      return dispatch(
        notificationAction({
          open: true,
          message: "Please select a property",
        })
      );
    }
    let request;
    if (unit?.id) {
      request = condeoApi.updateUnit(model, token, unit?.id);
    } else {
      request = condeoApi.createUnit(model, token);
    }
    request
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.general"),
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            dispatch(
              notificationAction({
                open: true,
                message: `Unit has been ${
                  unit ? "edited" : "added"
                } successfully`,
              })
            );
            if (unit) {
              onEditModeSwitch();
            } else {
              history.push("/units");
            }
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  }
  const redirectBack = () => {
    history.goBack();
  };
  const handleAddProperty = () => {
    history.push("/properties/new");
  };
  const getOptionLabel = (option) => `${option.label}`;

  const optionRenderer = (option, { selected }) => {
    return (
      <Fragment>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {getOptionLabel(option)}
      </Fragment>
    );
  };
  return (
    <Box p={2} mt={"20px"}>
      <Box className={styles.header}>
        <Typography variant="h6" color="textSecondary">
          {unit ? "Edit" : "Add"} Unit
        </Typography>
        {currentPath.split("/")[2] !== "new" && (
          <Tooltip title="Cancel">
            <IconButton onClick={onEditModeSwitch} id="edit-action">
              <Close />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Divider light />

      {properties && properties.length === 0 && (
        <Box height="35em">
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height="35em"
            textAlign="center"
          >
            <img
              src={emptyIcon}
              alt="No Properties"
              style={{ maxWidth: "12em" }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ margin: "1em" }}
            >
              Oops.. Looks like there are no Properties to add this Unit to.{" "}
              <br />
              To create a new Unit you need to have a Unit created first!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddProperty}
              startIcon={<AddIcon />}
              style={{ width: "24em" }}
            >
              New Property
            </Button>
          </Box>
        </Box>
      )}
      {properties && properties.length > 0 && (
        <Formik enableReinitialize={true}>
          <form onSubmit={formik.handleSubmit} className={classes.grid}>
            <Grid container spacing={3}>
              <Box width="100%" p="1em" pt="2em">
                <Grid item xs={12} style={{ margin: "1em 0 1em 0" }}>
                  <div className={styles.textFieldMinHeight}>
                    <TextField
                      id="unit-label"
                      label={t("AllProperties.label")}
                      fullWidth
                      variant="outlined"
                      name="label"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.label}
                    />
                    {formik.touched.label && formik.errors.label ? (
                      <div className={styles.error}>{formik.errors.label}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={styles.textFieldMinHeight}>
                    <TextField
                      id="unit-description"
                      label={t("AllProperties.description")}
                      fullWidth
                      variant="outlined"
                      name="description"
                      size="small"
                      multiline
                      rows={4}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className={styles.error}>
                        {formik.errors.description}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
                <Divider light />
                <Box display="flex" width="100%" mt={"1em"}>
                  <Grid
                    item
                    xs={6}
                    style={{ maxWidth: "50%", marginRight: "2em" }}
                  >
                    <div className={styles.textFieldContainer}>
                      <div className={styles.textFieldMinHeight}>
                        <TextField
                          id="unit-remarks"
                          label={t("Tenants.remarks")}
                          fullWidth
                          variant="outlined"
                          name="remarks"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.remarks}
                        />
                        {formik.touched.remarks && formik.errors.remarks ? (
                          <div className={styles.error}>
                            {formik.errors.remarks}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={styles.textFieldMinHeight}>
                        <TextField
                          id="unit-rooms"
                          label={t("Units.rooms")}
                          fullWidth
                          variant="outlined"
                          name="rooms"
                          size="small"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.rooms}
                        />
                        {formik.touched.rooms && formik.errors.rooms ? (
                          <div className={styles.error}>
                            {formik.errors.rooms}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={styles.textFieldMinHeight}>
                        <TextField
                          id="unit-net-rent"
                          label={t("Units.netRent")}
                          fullWidth
                          variant="outlined"
                          name="netRent"
                          size="small"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.netRent}
                        />
                        {formik.touched.netRent && formik.errors.netRent ? (
                          <div className={styles.error}>
                            {formik.errors.netRent}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={styles.textFieldMinHeight}>
                        <TextField
                          id="unit-living-space"
                          label={`${t("Units.livingSpace")} (m²)`}
                          fullWidth
                          variant="outlined"
                          name="livingSpace"
                          size="small"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.livingSpace}
                        />
                        {formik.touched.livingSpace &&
                        formik.errors.livingSpace ? (
                          <div className={styles.error}>
                            {formik.errors.livingSpace}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ maxWidth: "50%" }}>
                    <div className={styles.textFieldContainer}>
                      <div className={styles.textFieldMinHeight}>
                        <TextField
                          id="6"
                          label={`${t("Units.floorSpace")} (m²)`}
                          fullWidth
                          variant="outlined"
                          name="floorSpace"
                          size="small"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.floorSpace}
                        />
                        {formik.touched.floorSpace &&
                        formik.errors.floorSpace ? (
                          <div className={styles.error}>
                            {formik.errors.floorSpace}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={styles.textFieldMinHeight}>
                        <TextField
                          id="unit-floor"
                          label={t("Units.floor")}
                          fullWidth
                          variant="outlined"
                          name="floor"
                          size="small"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.floor}
                        />
                        {formik.touched.floor && formik.errors.floor ? (
                          <div className={styles.error}>
                            {formik.errors.floor}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={styles.textFieldMinHeight}>
                        <TextField
                          id="unit-utilities-rent"
                          label={t("Units.utilitiesRent")}
                          fullWidth
                          variant="outlined"
                          name="utilitiesRent"
                          size="small"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.utilitiesRent}
                        />
                        {formik.touched.utilitiesRent &&
                        formik.errors.utilitiesRent ? (
                          <div className={styles.error}>
                            {formik.errors.utilitiesRent}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                </Box>
              </Box>
              <div className={styles.propertyContainer}>
                {properties && (
                  <Autocomplete
                    id="unit-properties-list"
                    options={properties}
                    getOptionLabel={(option) => option?.label || ""}
                    className={styles.autoComplete}
                    onChange={(event, newProperty) => {
                      setSelectedProperty(newProperty);
                    }}
                    value={selectedProperty}
                    renderOption={optionRenderer}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("AllProperties.select")}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                )}
              </div>
            </Grid>
            <div className={styles.gridFooter}>
              <Button startIcon={<ArrowBackIcon />} onClick={redirectBack}>
                {t("Actions.back")}
              </Button>
              <Button
                startIcon={<SaveIcon />}
                type="submit"
                color="primary"
                variant="contained"
                className={styles.saveButton}
              >
                {t("Actions.save")} {unit && t("Actions.changes")}
              </Button>
            </div>
          </form>
        </Formik>
      )}
    </Box>
  );
}
