import React from "react";
import { Paper, Container } from "@material-ui/core";
import TenantForm from "../TenantDetails/TenantForm/TenantForm";

export default function AddEditTenant(props) {
  const id = props.tenantId;
  const { tenant } = props;
  return (
    <Container>
      <Paper>
        <TenantForm tenant={tenant} id={id} />
      </Paper>
    </Container>
  );
}
