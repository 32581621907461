import { Box, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./AddressData.module.scss";

const AddressData = ({ address }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={styles.addressHeader}>
        <Typography variant="h6" color="textSecondary">
          {t("Address.1")}
        </Typography>
      </div>
      <Box display="flex" padding={"1em"}>
        <Grid item xs={6} style={{ marginRight: "1em" }}>
          <List>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  Street
                </Typography>
                <Typography variant="subtitle2">{address?.street}</Typography>
              </Box>
            </ListItem>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  Street Number
                </Typography>
                <Typography variant="subtitle2">
                  {address?.streetNumber}
                </Typography>
              </Box>
            </ListItem>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  Place
                </Typography>
                <Typography variant="subtitle2">{address?.place}</Typography>
              </Box>
            </ListItem>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  Canton
                </Typography>
                <Typography variant="subtitle2">{address?.canton}</Typography>
              </Box>
            </ListItem>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  Country
                </Typography>
                <Typography variant="subtitle2">{address?.country}</Typography>
              </Box>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <List>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  Additional Street
                </Typography>
                <Typography variant="subtitle2">
                  {address?.additionalStreet}
                </Typography>
              </Box>
            </ListItem>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  Additional Street Number
                </Typography>
                <Typography variant="subtitle2">
                  {address?.additionalStreetNumber}
                </Typography>
              </Box>
            </ListItem>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  PoBox
                </Typography>
                <Typography variant="subtitle2">{address?.poBox}</Typography>
              </Box>
            </ListItem>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column" m={1.5} my={1}>
                <Typography variant="caption" color="textSecondary">
                  Postcode
                </Typography>
                <Typography variant="subtitle2">{address?.postcode}</Typography>
              </Box>
            </ListItem>
          </List>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default AddressData;
