import React, { useContext } from "react";
import { store } from "../../../store";
import {
  ListItem,
  Grid,
  List,
  Box,
  Typography,
} from "@material-ui/core";
import styles from "./ProfileData.module.scss";
import { useTranslation } from "react-i18next";

const ProfileData = () => {
  const { state } = useContext(store);
  const { user } = state;
  const { t } = useTranslation()
  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} md={12}>
        <Box>
          <List>
            <ListItem>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption" color="textSecondary">
                {t('Tenants.firstName')}
                </Typography>
                <Typography variant="subtitle1">{user?.name}</Typography>
              </Box>
            </ListItem>
            <ListItem>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption" color="textSecondary">
                {t('Tenants.lastName')}
                </Typography>
                <Typography variant="subtitle1">{user?.lastName}</Typography>
              </Box>
            </ListItem>
            <ListItem>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption" color="textSecondary">
                {t('Tenants.email')}
                </Typography>
                <Typography variant="subtitle1">{user?.email}</Typography>
              </Box>
            </ListItem>
            <ListItem>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption" color="textSecondary">
                {t('Organization.name')}
                </Typography>
                <Typography variant="subtitle1">
                  {user?.organization?.name}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProfileData;
