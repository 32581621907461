import React from 'react'
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({ component: Component, ...rest }) {
    const token = localStorage.getItem('auth_token')
    return (
        <Route
            {...rest}
            render={props =>
                token ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: props.location.pathname }} />
            }
        />
    );
}