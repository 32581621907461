import {
  List,
  ListItem,
  ListItemAvatar,
  Badge,
  ListItemText,
  Divider,
  Avatar,
  Link,
  Dialog,
  CircularProgress,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import { Close, PersonOutline, ThumbUpAltRounded } from "@material-ui/icons";
import React from "react";
import styles from "./Likes.module.scss";

const Likes = ({ postLikes, dialogState, onClose }) => {
  return (
    <Dialog open={dialogState} maxWidth="xl" onClose={onClose}>
      <div className={styles.dialogHeader}>
        <Typography variant="h6">Likes</Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <Divider />
      <Box px={1} py={2} pt={0}>
        {!postLikes && <CircularProgress />}
        {postLikes && (
          <div className={styles.postLikesContainer}>
            <List dense={false}>
              {postLikes?.map((like, index) => {
                return (
                  <React.Fragment key={like.id}>
                    <ListItem>
                      <ListItemAvatar>
                        <Badge
                          color="secondary"
                          badgeContent={
                            <ThumbUpAltRounded className={styles.badge} />
                          }
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <Avatar>
                            <PersonOutline />
                          </Avatar>
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Link>
                            {like?.likedBy?.firstName} {like?.likedBy?.lastName}
                          </Link>
                        }
                        secondary={like?.likedBy?.admin ? "Admin" : "Tenant"}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </div>
        )}
      </Box>
    </Dialog>
  );
};

export default Likes;
