import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import {
  rightButton,
  userInfoMenu,
  userInfoHeader,
  organizationInfo,
  userInfoFooter,
  avatar,
  menuIcon,
  radioGroup,
  hiddenOverflow
} from "./UserInfo.module.scss";
import { useHistory } from "react-router-dom";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grow,
  Paper,
  Popper,
  ClickAwayListener,
  Radio,
  FormControlLabel,
  RadioGroup,
  Box,
  Avatar
} from "@material-ui/core";
import logoutAction from "../../../store/actions/logoutAction";
import { store } from "../../../store";
import { useTranslation } from "react-i18next";
import {
  AccountCircleOutlined,
  SettingsOutlined,
  ArrowDropDown,
  LanguageOutlined,
} from "@material-ui/icons";
import { userInitials } from "../../../common/utils";

export default function UserInfo(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLanguageChange = (event) => {
    event.preventDefault();
    i18n.changeLanguage(event.target.value);
    setSelectedLanguage(event.target.value);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSignOut = (event) => {
    dispatch(logoutAction());
    history.push("/login");
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleUserProfile = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    history.push(`/users/${user?.id}/profile`);
  };

  const { user } = props;
  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={rightButton}
        color="inherit"
        endIcon={
          <ArrowDropDown style={{ fontSize: "30px", marginLeft: "-10px" }} />
        }
      >
        {user ? user.name : ""}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={userInfoMenu}>
              <div className={userInfoHeader}>
              <Avatar className={avatar}>
            <Typography variant="h6">
              {userInitials(user.name, user.lastName)}
            </Typography>
          </Avatar>
                <div className={hiddenOverflow}> 
                  <Typography>
                    {user?.name} {user?.lastName}
                  </Typography>
                  <Typography color="textSecondary">{user?.email}</Typography>
                </div>
              </div>
              <Divider />
              <div className={organizationInfo}>
                <Typography>{user?.organization?.name}</Typography>
              </div>
              <Divider />
              <ClickAwayListener onClickAway={handleClose}>
                <List>
                  <ListItem button onClick={(e) => handleUserProfile(e)}>
                    <AccountCircleOutlined className={menuIcon} />
                    <ListItemText primary={t("Profile.1")}></ListItemText>
                  </ListItem>
                  <ListItem button>
                    <SettingsOutlined className={menuIcon} />
                    <ListItemText
                      primary={t("Profile.settings")}
                    ></ListItemText>
                  </ListItem>
                  <ListItem id="form-select-list" name="form-select-list">
                    <LanguageOutlined className={menuIcon} />
                    <ListItemText primary={t('language')}></ListItemText>
                    <RadioGroup name="language" value={selectedLanguage}>
                      <Box className={radioGroup}>
                        <FormControlLabel
                          name="en"
                          control={<Radio color="secondary" />}
                          label='EN'
                          onChange={handleLanguageChange}
                          value={"en"}
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          name="de"
                          control={<Radio color="secondary" />}
                          label='DE'
                          value={"de"}
                          onChange={handleLanguageChange}
                          labelPlacement="start"
                        />
                      </Box>
                    </RadioGroup>
                  </ListItem>
                </List>
              </ClickAwayListener>
              <div className={userInfoFooter}>
                <Button color="secondary" onClick={handleSignOut}>
                  {t("Profile.signOut")}
                </Button>
                <Button color="secondary">{t("Profile.privacyPolicy")}</Button>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
