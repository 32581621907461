import React, { createContext, useReducer } from 'react'
import { FETCH_CURRENT_USER, SHOW_NOTIFICATION } from './actionTypes'

const initialState = {
  auth: {
    token: localStorage.getItem('auth_token'),
    isAuthenticated: false,
  },
  notification: {
    open: false,
    message: null,
  },
  user: JSON.parse(localStorage.getItem('user')),
  properties: {
    allProperties: null,
    editingMode: false,
  },
  pinboard: {
    selectedItem: 'published'
  },
  selectedDocument: [],
}

const store = createContext(initialState)
const { Provider } = store

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SHOW_NOTIFICATION:
        return {
          ...state,
          notification: {
            open: action.payload.open,
            message: action.payload.message,
          },
        }
      case 'LOGIN_SUCCESS':
        localStorage.setItem('auth_token', action.payload.token)
        return {
          ...state,
          auth: {
            token: action.payload.token,
            isAuthenticated: true,
          },
        }
      case 'LOGOUT_SUCCESS':
        localStorage.removeItem('auth_token')
        localStorage.removeItem('user')
        return {
          ...state,
          auth: {
            token: null,
            isAuthenticated: false,
          },
        }
      case FETCH_CURRENT_USER:
        localStorage.setItem('user', JSON.stringify(action.payload.user))
        return {
          ...state,
          user: action.payload.user,
        }
      case 'STORE_PROPERTY':
        return {
          ...state,
          properties: {
            ...state.properties,
            allProperties: action.payload.properties,
          },
        }
      case 'UPDATE_SELECTED_PROPERTY':
        return {
          ...state,
          properties: {
            ...state.properties,
            selectedProperty: action.payload.selectedProperty,
          },
        }
      case 'SET_EDITING_MODE':
        return {
          ...state,
          properties: {
            ...state.properties,
            editingMode: action.payload.editingMode,
          },
        }
      case 'SET_SELECTED_ITEM':
        return {
          ...state,
          pinboard: {
            ...state.pinboard,
            selectedItem: action.payload.selectedItem
          },
        }
      case 'STORE_DOCUMENT':
        return {
          ...state,
          selectedDocument: action.payload.selectedDocument
        }
      default:
        return state
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider }
