import { SHOW_NOTIFICATION } from '../actionTypes'

const notificationAction = payload => {
    return {
        type: SHOW_NOTIFICATION,
        payload
    }
}

export default notificationAction
