import React, { useContext } from 'react'
import {
  Card,
  Typography,
  TextField,
  Grid,
  Link,
  Button,
  Container,
  CardContent
} from '@material-ui/core'
import { container, submitButton, card, gridMargin } from '../auth.module.scss'
import { store } from '../../store'
import notificationAction from '../../store/actions/notificationAction'
import { useForm } from 'react-hook-form'
import condeoApi from '../../common/condeoApi'
export default function ResetPasswordPage() {
  const globalState = useContext(store)
  const { dispatch } = globalState

  const {
    register,
    handleSubmit,
    errors,
    // watch,
    // setError,
    // clearError,
  } = useForm()

  const hasError = (inputName) => !!(errors && errors[inputName])


  const onSubmit = (data) => {
    const model = {
      email: data.email,
    }
    condeoApi.resetPassword(model).then((result) => {
      result.json().then((response) => {
        if (!response.success) {
          dispatch(
            notificationAction({
              open: true,
              message: response.message,
            })
          )
        } else {
          dispatch(
            notificationAction({
              open: true,
              message:
                'An email was sent for you to confirm the reset password process.',
            })
          )
        }
      })
    })
  }
  return (
    <Container className={container}>
      <Card className={card}>
        <CardContent>
          <Typography component='h1' variant='h5'>
            Reset Password
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                size='small'
                inputRef={register({
                  required: 'Email Address is required!',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address',
                  },
                })}
                error={hasError('email')}
                helperText={
                  hasError('email') ? `${errors.email.message}` : null
                }
              />
              <div className={submitButton}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                >
                  Reset Password
                </Button>
              </div>
              <Grid container className={gridMargin}>
                <Grid item>
                  <Link href='/login' variant='body2'>
                    {'Back to login'}
                  </Link>
                </Grid>
              </Grid>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}
