/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useCallback, useEffect, useState } from "react";
import { store } from "../../../store";
import {
  TextField,
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  CardHeader,
  Card,
  Divider,
  CardContent,
  CardActions,
  Container,
} from "@material-ui/core";
import {
  BackupOutlined,
  HighlightOff,
  CheckCircleOutlineOutlined,
  InfoOutlined,
  Done,
  ArrowBack,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import styles from "./DocumentDetails.module.scss";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import condeoApi from "../../../common/condeoApi";
import notificationAction from "../../../store/actions/notificationAction";
import { useHistory } from "react-router-dom";
import MultiSelect from "../../../common/Autocomplete/MultiSelect";

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  formContainer: {
    width: "95%",
    margin: "10px 0 0 10px",
    animation: "fadeIn 2s",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 300,
  },
}));

export default function DocumentDetails(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { user } = state;
  const { token } = state.auth;
  const history = useHistory();
  const [properties, setProperties] = useState(null);
  const [tenants, setTenants] = useState(null);
  const [units, setUnits] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(
    state.selectedDocument
  );
  const [selectedFile, setSelectedFile] = useState(
    state.selectedDocument.fileName
  );
  const [referenceTarget, setReferenceTarget] = useState("properties");
  const [document, setDocument] = useState(null);
  const [documentReferences, setDocumentReferences] = useState([]);

  useEffect(() => {
    fetchProperties();
    fetchDocument();
    getDocumentReferences();
  }, []);

  const getDocumentReferences = () => {
    const id = props.location.pathname.split("/")[2];
    condeoApi
      .getDocumentReferences(token, id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setDocumentReferences(response.references);
              if (response.references[0]?.target === "tenants") {
                fetchTenants();
                setReferenceTarget("tenants");
              } else if (response.references[0]?.target === "units") {
                setReferenceTarget("units");
                fetchUnits();
              } else {
                fetchProperties();
                setReferenceTarget("properties");
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchDocument = () => {
    const id = props.location.pathname.split("/")[2];
    condeoApi
      .fetchDocument(token, id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setDocument(response.document);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const updateDocument = (values) => {
    let model;
    let request;

    if (values) {
      model = {
        name: values.name,
        documentId: document?.id,
        documents: [acceptedFiles[0]],
        reference: {
          list: documentReferences,
        },
      };
      if (referenceTarget === "properties") {
        model.reference.target = "properties";
      } else if (referenceTarget === "units") {
        model.reference.target = "units";
      } else {
        model.reference.target = "tenants";
      }
      console.log(model);
      if (acceptedFiles.length === 0) {
        request = condeoApi.updateDocumentInfo(token, model);
      } else {
        request = condeoApi.updateDocumentAttachment(token, model);
      }
      request
        .then((result) => {
          if (result.ok) {
            result.json().then((response) => {
              console.log("response: ", response);
              if (response) {
                console.log(response);
                dispatch(
                  notificationAction({
                    open: true,
                    message: "Document updated successfully!",
                  })
                );
                if (
                  response.reference &&
                  response.reference.list.length !== 0
                ) {
                }
                history.push("/documents");
              }
            });
          }
        })
        .catch((err) => {
          console.log("Could not communicate with the server!", err.message);
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        });
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "This field is Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: selectedDocument?.name ? selectedDocument.name : "",
    },
    validate,
    onSubmit: (values) => {
      updateDocument(values);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    fetchDocument();
    setSelectedDocument(acceptedFiles[0]);
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const getLabelByReference = () => {
    switch (referenceTarget) {
      case "properties":
        return t("AllProperties.1");
      case "units":
        return t("units");
      case "tenants":
        return t("tenants");
      default:
        return "Select an option";
    }
  };

  const removeSelectedFile = () => {
    condeoApi
      .deleteUploadedFile(token, selectedDocument?.id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              fetchDocument();
              setSelectedFile(null);
              dispatch(
                notificationAction({
                  open: true,
                  message: "File has been deleted successfully",
                })
              );
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const handlePropertyTab = () => {
    setReferenceTarget("properties");
    setDocumentReferences([]);
    fetchProperties();
  };

  const handleUnitsTab = () => {
    setReferenceTarget("units");
    setDocumentReferences([]);
    fetchUnits();
  };

  const handleTenantsTab = () => {
    setReferenceTarget("tenants");
    setDocumentReferences([]);
    fetchTenants();
  };

  const fetchUnits = () => {
    condeoApi
      .fetchUnits(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            setUnits(
              response.units.filter(
                (unit) => unit.organizationId === user?.organization?.id
              )
            );
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchTenants = () => {
    condeoApi
      .fetchTenants(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setTenants(
                response.tenants.filter(
                  (t) =>
                    t.unit.property.organizationId === user?.organization?.id
                )
              );
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchProperties = () => {
    condeoApi
      .fetchProperties(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setProperties(
                response.properties.filter(
                  (property) =>
                    property.organizationId === user.organization?.id
                )
              );
            }
          });
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const getTabValueByReference = (reference) => {
    switch (reference) {
      case "properties":
        return 0;
      case "units":
        return 1;
      case "tenants":
        return 2;
      default:
        return 0;
    }
  };

  const getIconByFileType = (fileName) => {
    const type = fileName
      ?.toLowerCase()
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    let icon = <></>;
    switch (type) {
      case "pdf":
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/pdf-icon.png")}
          />
        );
        break;
      case "doc":
      case "docx":
      case "docm":
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/word-icon.png")}
          />
        );
        break;
      case "ppt":
      case "pptx":
      case "pps":
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/powerpoint-icon.png")}
          />
        );
        break;
      case "xlsx":
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/exel-icon.png")}
          />
        );
        break;
      case "txt":
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/text-icon.png")}
          />
        );
        break;
      case "png":
      case "jpg":
      case "jpeg":
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/image-icon.png")}
          />
        );
        break;
      case "mp3":
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/mp3-icon.png")}
          />
        );
        break;
      case "wav":
      case "mp4":
      case "wma":
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/image-icon.png")}
          />
        );
        break;
      default:
        icon = (
          <img
            alt="document-file"
            src={require("../../../assets/Images/Files/default-icon.png")}
          />
        );
    }
    return icon;
  };

  const files = (
    <ListItem key={selectedDocument?.path}>
      <CheckCircleOutlineOutlined className={styles.successStyle} />
      <ListItemAvatar>
        {getIconByFileType(
          selectedDocument?.path || selectedDocument?.fileName
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="body2">
            {selectedDocument?.fileName || selectedDocument?.path}
          </Typography>
        }
        secondary={
          selectedDocument?.size && (
            <Typography variant="caption">
              {selectedDocument?.size} Bytes
            </Typography>
          )
        }
      />
      <ListItemSecondaryAction>
        <Tooltip title="Remove file">
          <IconButton
            edge="end"
            aria-label="delete"
            size="small"
            onClick={removeSelectedFile}
          >
            <HighlightOff />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Container>
      <Card className={classes.formContainer}>
        <CardHeader
          title={<Typography variant="h6">{t("documentDetails")}</Typography>}
          subheader={
            <Typography variant="caption" color="textSecondary">
              {t("documentInfo")}
            </Typography>
          }
        ></CardHeader>
        <Divider />
        <CardContent>
          <Formik enableReinitialize={true}>
            <form onSubmit={formik.handleSubmit}>
              <Box className={styles.addFormsContainer}>
                <Box className={styles.textFieldMinHeight}>
                  <TextField
                    id="name"
                    variant="outlined"
                    label={t("documentLabel")}
                    name="name"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    fullWidth
                    autoFocus
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className={styles.error}>{formik.errors.name}</div>
                  ) : (
                    ""
                  )}
                </Box>
                <Box component="div" className={styles.defaultContainer}>
                  <InfoOutlined
                    fontSize="small"
                    className={styles.warningStyle}
                  />
                  <Typography color="textSecondary" variant="caption">
                    {t("documentRefInfo")}
                  </Typography>
                </Box>
                <Paper>
                  <Tabs
                    value={getTabValueByReference(referenceTarget)}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab
                      label={t("AllProperties.1")}
                      onClick={() => handlePropertyTab()}
                    />
                    <Tab label={t("units")} onClick={() => handleUnitsTab()} />
                    <Tab
                      label={t("tenants")}
                      onClick={() => handleTenantsTab()}
                    />
                  </Tabs>
                </Paper>
                <Box component="span" m={1} />
                {!properties && referenceTarget === "properties" && (
                  <CircularProgress color="primary" />
                )}
                {properties && referenceTarget === "properties" && (
                  <MultiSelect
                    items={properties}
                    selectedItems={documentReferences}
                    label={`${t("Actions.select")} ${getLabelByReference(
                      "properties"
                    )}`}
                    searchByProperty="propertyId"
                    onChange={setDocumentReferences}
                  />
                )}
                {!units && referenceTarget === "units" && (
                  <CircularProgress color="primary" />
                )}
                {units && referenceTarget === "units" && (
                  <MultiSelect
                    items={units}
                    selectedItems={documentReferences}
                    label={`${t("Actions.select")} ${getLabelByReference(
                      "units"
                    )}`}
                    searchByProperty="unitId"
                    onChange={setDocumentReferences}
                  />
                )}
                {!tenants && referenceTarget === "tenants" && (
                  <CircularProgress color="primary" />
                )}
                {tenants && referenceTarget === "tenants" && (
                  <MultiSelect
                    items={tenants}
                    selectedItems={documentReferences}
                    label={`${t("Actions.select")} ${getLabelByReference(
                      "tenants"
                    )}`}
                    searchByProperty="tenantId"
                    onChange={setDocumentReferences}
                  />
                )}
                <Box component="span" m={1} />
                <section className={styles.fileUploadContainer}>
                  {!selectedFile && (
                    <Box {...getRootProps({ className: styles.dropZone })}>
                      <input
                        {...getInputProps()}
                        multiple={false}
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      />
                      <Box className={styles.fileUploadInfo}>
                        <Typography>{t("dragFiles")}</Typography>
                        <BackupOutlined fontSize="large" />
                      </Box>
                    </Box>
                  )}
                  {selectedFile && (
                    <aside>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("selectedFile")}
                      </Typography>
                      <Paper className={styles.acceptedFilesContainer}>
                        <List>{files}</List>
                      </Paper>
                    </aside>
                  )}
                </section>
              </Box>
              <CardActions className={styles.cardActions} p={0}>
                <Button
                  startIcon={<ArrowBack />}
                  onClick={() => history.goBack()}
                >
                  {t("Actions.back")}
                </Button>
                <Button
                  startIcon={<Done />}
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={styles.savePropertyButton}
                  onClick={() => updateDocument()}
                >
                  {t("Actions.save")}{" "}
                  {props.match?.path.endsWith("new")
                    ? ""
                    : t("Actions.changes")}
                </Button>
              </CardActions>
            </form>
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}
