import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Typography,
  TextField,
  Grid,
  Link,
  Button,
  Container,
  CardContent,
} from "@material-ui/core";
import { container, submitButton, card, gridMargin } from "../auth.module.scss";
import { useQueryParams } from "hookrouter";
import { store } from "../../store";
import notificationAction from "../../store/actions/notificationAction";
import { useForm } from "react-hook-form";
import loginAction from "../../store/actions/loginAction";
import userAction from "../../store/actions/userAction";
import condeoApi from "../../common/condeoApi";

export default function ConfirmAccountPage(props) {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setError,
    clearError,
  } = useForm();

  const hasError = (inputName) => !!(errors && errors[inputName]);
  const watchAllFields = watch();

  const handle = () => {
    if (
      errors.confirmPassword.type === "required" ||
      errors.confirmPassword.type === "notMatch"
    ) {
      return `${errors.confirmPassword.message}`;
    }
  };

  const fetchUser = (token) => {
    condeoApi.fetchUser(token).then((result) => {
      result.json().then((response) => {
        if (response.id) {
          dispatch(
            userAction({
              user: response,
            })
          );
          if (response.admin && !response.organization) {
            history.push("/setup-organization");
          } else {
            history.push("/");
          }
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: response.message,
            })
          );
        }
      });
    });
  };

  const queryParams = useQueryParams();
  const history = useHistory();

  const onSubmit = (data) => {
    const model = {
      email: props.email || queryParams[0].email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      confirmationToken: props.confirmationToken
        ? props.confirmationToken
        : queryParams[0].confirmationToken,
      invitationToken: props.invitationToken,
    };
    condeoApi.confirmAccount(model).then((result) => {
      result.json().then((response) => {
        if (response.success) {
          dispatch(
            loginAction({
              token: response.token,
            })
          );
          localStorage.setItem("activeStep", 0);
          fetchUser(response.token);
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: response.message,
            })
          );
        }
      });
    });
  };
  return (
    <Container className={container}>
      <Card className={card}>
        <CardContent>
          <Typography component="h1" variant="h5">
            Confirm Account
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                size="small"
                inputRef={register({
                  required: "First Name is required!",
                })}
                error={hasError("firstName")}
                helperText={
                  hasError("firstName") ? `${errors.firstName.message}` : null
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="lastName"
                label="Last Name"
                id="lastName"
                size="small"
                inputRef={register({
                  required: "Last Name is required!",
                })}
                error={hasError("lastName")}
                helperText={
                  hasError("lastName") ? `${errors.lastName.message}` : null
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                size="small"
                inputRef={register({
                  required: "Password is required!",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters.",
                  },
                  notMatch: {},
                })}
                error={hasError("password")}
                helperText={
                  hasError("password") ? `${errors.password.message}` : null
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                size="small"
                onFocus={() => {
                  if (
                    watchAllFields.password !== watchAllFields.confirmPassword
                  ) {
                    setError(
                      "confirmPassword",
                      "notMatch",
                      "Password does not match"
                    );
                  } else clearError("confirmPassword");
                }}
                onChange={() => {
                  if (
                    watchAllFields.password !== watchAllFields.confirmPassword
                  ) {
                    setError(
                      "confirmPassword",
                      "notMatch",
                      "Password does not match"
                    );
                  } else clearError("confirmPassword");
                }}
                inputRef={register({
                  required: "Confirm Password is required!",
                  validate: (value) => value === watchAllFields.password,
                })}
                error={hasError("confirmPassword")}
                helperText={hasError("confirmPassword") ? handle() : null}
              />
              <div className={submitButton}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Confirm Account
                </Button>
              </div>
              <Grid container className={gridMargin}>
                <Grid item>
                  <Link href="/login" variant="body2">
                    {"Back to login"}
                  </Link>
                </Grid>
              </Grid>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}
