import React from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Checkbox } from "@material-ui/core"

const MultiSelect = ({
  items,
  label,
  noOptionsText,
  selectedItems,
  searchByProperty,
  style,
  onChange
}) => {
  const allSelected = items?.length === selectedItems?.length
  const handleToggleOption = selectedItems => onChange(selectedItems)
  const handleClearOptions = () => onChange([])
  const getOptionLabel = option => `${option.label || option.firstName}`
  const handleSelectAll = isSelected => {
    if (isSelected) {
      onChange(items)
    } else {
      handleClearOptions()
    }
  }

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected)
  }

  const handleChange = (event, selectedItems, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedItems.find(option => option.value === "select-all")) {
        handleToggleSelectAll()
        let result = []
        result = items.filter(el => el.value !== "select-all")
        return onChange(result)
      } else {
        handleToggleOption && handleToggleOption(selectedItems)
        return onChange(selectedItems)
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions()
    }
  }
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      items?.length > 0 && option.value === "select-all"
        ? { checked: allSelected }
        : {}
    return (
      <>
        {option.value !== "select-all" &&
          <>
            <Checkbox
              color="primary"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
              {...selectAllProps}
            />
            {getOptionLabel(option)}
          </>
        }
        {items?.length > 0 && option.value === "select-all" &&
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <p style={{ margin: 0 }}>
              Select All
          </p>
          </div>
        }
      </>
    )
  }
  const inputRenderer = params => (
    <TextField
      {...params}
      label={label}
      variant="outlined"
    />
  )

  const filter = createFilterOptions()
  return (
    <Autocomplete
      multiple
      limitTags={5}
      options={items}
      value={items?.filter(item => selectedItems?.some(ref => ref[searchByProperty] === item.id || ref.id === item.id))}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.id === value.id}
      noOptionsText={noOptionsText}
      style={style}
      filterOptions={(options, params) => {
        if (items?.length > 0) {
          const filtered = filter(options, params)
          return [{ label: 'Select All', value: "select-all" }, ...filtered]
        } else if (items?.length === 0) {
          const filtered = filter(options, params)
          return [...filtered]
        }
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  )
}

export default MultiSelect
