import React from "react";
import {
  Typography,
  IconButton,
  List,
  ListItem,
  Box,
  Divider,
  Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import styles from "./UnitData.module.scss";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import {
  AttachMoneyRounded,
  CheckBoxOutlineBlankOutlined,
  ListAltOutlined,
  MessageOutlined,
  ZoomOutMapOutlined,
} from "@material-ui/icons";

export default function UnitData({ unit, onEditModeSwitch }) {
  const { t } = useTranslation();

  return (
    <Box p={2} mt={"20px"}>
      <Box className={styles.header}>
        <Typography variant="h6" color="textSecondary">
          {t("unit")}
        </Typography>
        <Tooltip title={"Edit"}>
          <IconButton onClick={onEditModeSwitch}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider light />
      <Box width="100%">
        <Box p={"1em"} pb={0}>
          <List>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption" color="textSecondary">
                  Label
                </Typography>
                <Typography variant="body2">{unit?.label}</Typography>
              </Box>
            </ListItem>
            <ListItem className={styles.listItem}>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption" color="textSecondary">
                  Description
                </Typography>
                <Typography variant="subtitle2">{unit?.description}</Typography>
              </Box>
            </ListItem>
          </List>
          <Divider light />
        </Box>
      </Box>
      <Box display="flex" width="100%" padding={"1em"} flexDirection="column">
        <Box display="flex" width="100%">
          <Grid item xs={6} style={{ marginRight: "1em" }}>
            <List>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Remarks
                  </Typography>
                  <Typography variant="subtitle2">{unit?.remarks}</Typography>
                </Box>
                <MessageOutlined className={styles.icon} />
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Rooms
                  </Typography>
                  <Typography variant="subtitle2">{unit?.rooms}</Typography>
                </Box>
                <CheckBoxOutlineBlankOutlined className={styles.icon} />
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Net rent
                  </Typography>
                  <Typography variant="subtitle2">{unit?.netRent}</Typography>
                </Box>
                <AttachMoneyRounded className={styles.icon} />
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Utilities Rent
                  </Typography>
                  <Typography variant="subtitle2">
                    {unit?.utilitiesRent}
                  </Typography>
                </Box>
                <AttachMoneyRounded className={styles.icon} />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Living Space
                  </Typography>
                  <Typography variant="subtitle2">
                    {unit?.livingSpace} m²
                  </Typography>
                </Box>
                <ZoomOutMapOutlined className={styles.icon} />
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Floor Space
                  </Typography>
                  <Typography variant="subtitle2">
                    {unit?.floorSpace} m²
                  </Typography>
                </Box>
                <ZoomOutMapOutlined className={styles.icon} />
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Floor
                  </Typography>
                  <Typography variant="subtitle2">{unit?.floor}</Typography>
                </Box>
                <ListAltOutlined className={styles.icon} />
              </ListItem>
            </List>
          </Grid>
        </Box>
        <Grid item xs={12}>
          <ListItem className={styles.listItem}>
            <Box display="flex" flexDirection="column" m={1.5} my={1}>
              <Typography variant="caption" color="textSecondary">
                Property
              </Typography>
              <Typography variant="subtitle2">
                {unit?.property?.label}
              </Typography>
            </Box>
            <CheckBoxOutlineBlankOutlined className={styles.icon} />
          </ListItem>
        </Grid>
      </Box>
    </Box>
  );
}
