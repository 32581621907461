import React, { useContext } from 'react'
import {
  Card,
  Typography,
  TextField,
  Grid,
  Link,
  Button,
  Container,
  CardContent,
} from '@material-ui/core'
import { container, submitButton, card, gridMargin } from '../auth.module.scss'
import { useHistory } from 'react-router-dom'
import { store } from '../../store'
import notificationAction from '../../store/actions/notificationAction'
import loginAction from '../../store/actions/loginAction'
import fetchUserAction from '../../store/actions/userAction'
import { useForm } from 'react-hook-form'
import condeoApi from '../../common/condeoApi'
import { useTranslation } from 'react-i18next'

export default function LoginPage(props) {
  const { dispatch } = useContext(store)

  const { register, handleSubmit, errors } = useForm()
  const { t } = useTranslation()

  const history = useHistory()
  const returnPath = props.location.state

  const fetchUser = (token) => {
    condeoApi.fetchUser(token).then((result) => {
      result.json().then((response) => {
        if (response.id) {
          dispatch(
            fetchUserAction({
              user: response,
            })
          )
          if (response.admin && !response.onBoarding) {
            history.push('/setup-organization')
          } else if (returnPath) {
            history.push(returnPath)
          } else {
            history.push('/')
          }
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: response.message,
            })
          )
        }
      })
    })
  }

  const hasError = (inputName) => !!(errors && errors[inputName])

  const onSubmit = (data) => {
    const model = {
      email: data.email,
      password: data.password,
    }
    condeoApi
      .login(model)
      .then((result) => {
        result.json().then((response) => {
          if (response.token) {
            dispatch(
              loginAction({
                token: response.token,
              })
            )
            fetchUser(response.token)
          } else {
            dispatch(
              notificationAction({
                open: true,
                message: response.message,
              })
            )
          }
        })
      })
      .catch((err) => {
        console.log('Could not communicate with server!', err.message)
        dispatch(
          notificationAction({
            open: true,
            message: t('Errors.general'),
          })
        )
      })
  }

  return (
    <Container className={container}>
      <Card className={card}>
        <CardContent>
          <Typography component='h1' variant='h5'>
            Login
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                size='small'
                inputRef={register({
                  required: 'Email Address is required!',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address',
                  },
                })}
                error={hasError('email')}
                helperText={
                  hasError('email') ? `${errors.email.message}` : null
                }
              />
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                name='password'
                label='Password'
                type='password'
                size='small'
                id='password'
                inputRef={register({
                  required: 'Password is required!',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters.',
                  },
                })}
                error={hasError('password')}
                helperText={
                  hasError('password') ? `${errors.password.message}` : null
                }
              />
              <div className={submitButton}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  id='submit'
                >
                  Sign In
                </Button>
              </div>
              <Grid container className={gridMargin}>
                <Grid item xs>
                  <Link href='/reset-password' variant='body2'>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href='/signup' variant='body2'>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}
