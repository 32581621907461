import React, { useContext } from "react";
import {
  Collapse,
  CardContent,
  Box,
  Typography,
  Avatar,
  CircularProgress,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { userInitials } from "../../../common/utils";
import styles from "./Comments.module.scss";
import { useTranslation } from "react-i18next";
import { HighlightOff } from "@material-ui/icons";
import { store } from "../../../store";

const Comments = ({
  expanded,
  addPostComment,
  postComments,
  post,
  handleCommentChange,
  commentValue,
  deletePostComment,
}) => {
  const { t } = useTranslation();
  const {
    state: { user },
  } = useContext(store);
  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent className={styles.collapseContainer}>
        {!postComments && <CircularProgress color="primary" />}
        <Box key={post?.id} className={styles.commentsWrapper} pt={0}>
          {postComments?.map((comment, index) => {
            return (
              <div className={styles.commentContainer} key={comment?.id}>
                <Avatar aria-label="comment-avatar" className={styles.avatar}>
                  {userInitials(
                    comment?.commentedBy?.firstName,
                    comment?.commentedBy?.lastName
                  )}
                </Avatar>
                <div className={styles.commentInfo}>
                  <Box>
                    <Typography variant="subtitle2">
                      {comment?.commentedBy?.firstName}{" "}
                      {comment?.commentedBy?.lastName}
                    </Typography>
                    <Typography variant="caption">
                      {comment?.content}
                    </Typography>
                  </Box>
                  {comment?.commentedBy?.id === user?.id && (
                    <Tooltip title="Delete comment">
                      <IconButton
                        size="small"
                        onClick={() => deletePostComment(comment?.id, post)}
                      >
                        <HighlightOff fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
        </Box>
        {post?.state === "published" && (
          <Box mb={1.5} ml={0} mr={0} mt={1}>
            <TextField
              name="add-comment"
              fullWidth
              variant="outlined"
              color="primary"
              multiline
              autoFocus
              size="small"
              onChange={handleCommentChange}
              label={t("addComment")}
              value={commentValue}
            ></TextField>
            <Collapse in={!!commentValue} timeout="auto" unmountOnExit>
              <Box mt={1}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(event) => addPostComment(event, post)}
                >
                  {t("Actions.post")}
                </Button>
              </Box>
            </Collapse>
          </Box>
        )}
      </CardContent>
    </Collapse>
  );
};

export default Comments;
