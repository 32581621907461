import React, { Fragment } from "react";
import styles from "./AddressForm.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { TextField, Grid, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  leftGrid: {
    marginRight: "30px",
  },
  rightGrid: {
    minHeight: "30.5em",
    marginRight: "15px",
  },
  typography: {
    width: "50%",
    fontSize: "20px",
  },
}));
export default function AddressForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { formik, id } = props;
  return (
    <Fragment>
      <div className={styles.addressHeader}>
        <Typography variant="h6" color="textSecondary">
          {t("Address.1")}
        </Typography>
      </div>
      <div className={styles.addressContainer}>
        <Grid
          item
          xs={6}
          style={{ maxWidth: "100%" }}
          className={id ? classes.rightGrid : classes.leftGrid}
        >
          <div className={styles.textFieldContainer}>
            <div className={styles.textFieldMinHeight}>
              <TextField
                id="9"
                variant="outlined"
                label={t("Address.street")}
                fullWidth
                name="street"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.street}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />
              {formik.touched.street && formik.errors.street ? (
                <div className={styles.error}>{formik.errors.street}</div>
              ) : (
                ""
              )}
            </div>

            <div className={styles.textFieldMinHeight}>
              <TextField
                id="10"
                label={t("Address.streetNumber")}
                fullWidth
                type="number"
                variant="outlined"
                name="streetNumber"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.streetNumber}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />
              {formik.touched.streetNumber && formik.errors.streetNumber ? (
                <div className={styles.error}>{formik.errors.streetNumber}</div>
              ) : (
                ""
              )}
            </div>
            <div className={styles.textFieldMinHeight}>
              <TextField
                id="11"
                label={t("Address.place")}
                fullWidth
                variant="outlined"
                name="place"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.place}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />
              {formik.touched.place && formik.errors.place ? (
                <div className={styles.error}>{formik.errors.place}</div>
              ) : (
                ""
              )}
            </div>
            <div className={styles.textFieldMinHeight}>
              <TextField
                id="12"
                label={t("Address.canton")}
                fullWidth
                variant="outlined"
                name="canton"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.canton}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />
              {formik.touched.canton && formik.errors.canton ? (
                <div className={styles.error}>{formik.errors.canton}</div>
              ) : (
                ""
              )}
            </div>
            <div className={styles.textFieldMinHeight}>
              <TextField
                id="13"
                label={t("Address.country")}
                fullWidth
                variant="outlined"
                name="country"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />

              {formik.touched.country && formik.errors.country ? (
                <div className={styles.error}>{formik.errors.country}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "100%" }}>
          <div className={styles.textFieldContainer}>
            <div className={styles.textFieldMinHeight}>
              <TextField
                id="14"
                label={t("Address.additionalStreet")}
                fullWidth
                variant="outlined"
                name="additionalStreet"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.additionalStreet}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />
            </div>
            <div className={styles.textFieldMinHeight}>
              <TextField
                id="15"
                label={t("Address.additionalStreetNumber")}
                fullWidth
                type="number"
                size="small"
                onChange={formik.handleChange}
                name="additionalStreetNumber"
                variant="outlined"
                value={formik.values.additionalStreetNumber}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />
            </div>
            <div className={styles.textFieldMinHeight}>
              <TextField
                id="16"
                label={t("Address.poBox")}
                fullWidth
                type="number"
                variant="outlined"
                name="poBox"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.poBox}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />

              {formik.touched.poBox && formik.errors.poBox ? (
                <div className={styles.error}>{formik.errors.poBox}</div>
              ) : (
                ""
              )}
            </div>
            <div className={styles.textFieldMinHeight}>
              <TextField
                id="17"
                label={t("Address.postCode")}
                fullWidth
                type="number"
                name="postCode"
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postCode}
                InputProps={{
                  readOnly: props.editingMode,
                }}
              />
              {formik.touched.postCode && formik.errors.postCode ? (
                <div className={styles.error}>{formik.errors.postCode}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </div>
    </Fragment>
  );
}
