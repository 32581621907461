/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { IconButton, Divider, Box, Button, Paper } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  PeopleAltOutlined,
  HomeWorkOutlined,
  NotificationsNoneOutlined,
  CheckBoxOutlineBlankOutlined,
  AddCircleOutline,
  ArrowRightAlt,
} from "@material-ui/icons";
import condeoApi from "../../common/condeoApi";
import notificationAction from "../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { store } from "../../store";
import {
  header,
  flexCenter,
  container,
  cardHeader,
  cardContent,
  itemsCount,
  root,
  tenantIcon,
  propertyIcon,
  subtitle,
  unitIcon,
  sideCard,
  sideButton,
  sideCardsContainer,
  sideCardContent,
  emptyTenantsState,
} from "./Dashboard.module.scss";
import TenantsCard from "./TenantsCard/TenantsCard";
export default function Dashboard() {
  const [tenants, setTenants] = React.useState(null);
  const [propertiesCount, setPropertiesCount] = React.useState(null);
  const [unitsCount, setUnitsCount] = React.useState(null);
  const { dispatch, state } = useContext(store);
  const { user } = state;
  const { token } = state?.auth;
  const { t } = useTranslation();
  const history = useHistory();
  const currentDate = moment(Date.now());

  useEffect(() => {
    fetchTenants();
    fetchProperties();
    fetchUnits();
  }, []);

  const fetchTenants = () => {
    condeoApi
      .fetchTenants(token, 3)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setTenants(
                response?.tenants?.filter(
                  (t) =>
                    t.unit?.property?.organizationId === user?.organization?.id
                )
              );
            }
          });
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchUnits = () => {
    condeoApi
      .fetchUnits(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setUnitsCount(
                response?.units?.filter(
                  (unit) => unit?.organizationId === user?.organization?.id
                ).length
              );
            }
          });
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchProperties = () => {
    condeoApi
      .fetchProperties(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setPropertiesCount(response?.properties?.length);
            }
          });
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const navigateToDetails = (route) => {
    history.push(`/${route}`);
  };

  const handleTenantDetails = (tenant) => {
    localStorage.setItem(
      "propertyId",
      JSON.stringify(tenant?.unit?.property?.id)
    );
    history.push(`/tenants/${tenant?.id}/details`);
  };

  return (
    <Fragment>
      <div className={header}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          style={{ fontFamily: "Poppins-Medium" }}
        >
          {t("welcome")}, {user?.name}
        </Typography>
        <div className={flexCenter}>
          <IconButton size="small">
            <NotificationsNoneOutlined />
          </IconButton>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={subtitle}
          >
            {currentDate.format("dddd, DD MMM")}
          </Typography>
        </div>
      </div>
      <Divider />
      <div className={container}>
        <Box mt={1.5} mb={1.5}>
          <Typography variant="h6">{t("myOrganization")}</Typography>
        </Box>
        <div className={flexCenter}>
          <Card
            className={root}
            onClick={() => navigateToDetails("properties")}
          >
            <div className={cardHeader}>
              <IconButton size="small">
                <MoreHorizIcon />
              </IconButton>
            </div>
            <CardContent className={cardContent}>
              <div>
                <HomeWorkOutlined className={propertyIcon} />
                <Typography variant="subtitle2" className={subtitle}>
                  {t("AllProperties.properties").toUpperCase()}
                </Typography>
              </div>
              <Typography variant="h3" component="p" className={itemsCount}>
                {propertiesCount}
              </Typography>
            </CardContent>
          </Card>
          <Card className={root} onClick={() => navigateToDetails("units")}>
            <div className={cardHeader}>
              <IconButton size="small">
                <MoreHorizIcon />
              </IconButton>
            </div>
            <CardContent className={cardContent}>
              <div>
                <CheckBoxOutlineBlankOutlined className={unitIcon} />
                <Typography variant="subtitle2" className={subtitle}>
                  {t("units")}
                </Typography>
              </div>
              <Typography variant="h3" component="p" className={itemsCount}>
                {unitsCount}
              </Typography>
            </CardContent>
          </Card>
          <Card className={root} onClick={() => navigateToDetails("tenants")}>
            <div className={cardHeader}>
              <IconButton size="small">
                <MoreHorizIcon />
              </IconButton>
            </div>
            <CardContent className={cardContent}>
              <div>
                <PeopleAltOutlined className={tenantIcon} />
                <Typography variant="subtitle2" className={subtitle}>
                  {t("Tenants.1").toUpperCase()}
                </Typography>
              </div>
              <Typography variant="h3" component="p" className={itemsCount}>
                {tenants?.length}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className={container}>
        <Box mt={1.5} mb={1.5}>
          <Typography variant="h6">{t("tenants")}</Typography>
          <Typography variant="caption" color="textSecondary">
            {t("latestTenants")}
          </Typography>
        </Box>
        <div className={flexCenter}>
          {tenants?.map((tenant) => {
            return (
              <TenantsCard
                key={tenant?.id}
                tenant={tenant}
                handleTenantDetails={(tenant) => handleTenantDetails(tenant)}
              />
            );
          })}
          {tenants && tenants.length === 0 && (
            <Paper className={emptyTenantsState}>
              <Typography color="textSecondary" style={{ marginRight: "1em" }}>
                {t("emptyTenants")}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigateToDetails("tenants/new")}
              >
                {t("newTenant")}
              </Button>
            </Paper>
          )}

          {tenants && tenants.length > 0 && (
            <div className={sideCardsContainer}>
              <Card
                className={sideCard}
                onClick={() => navigateToDetails("tenants/new")}
              >
                <CardContent className={sideCardContent}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography color="textSecondary">{t("addNew")}</Typography>
                    <AddCircleOutline className={sideButton} />
                  </Box>
                </CardContent>
              </Card>
              <Card
                className={sideCard}
                onClick={() => navigateToDetails("tenants")}
              >
                <CardContent className={sideCardContent}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography color="textSecondary">
                      {t("viewAll")}
                    </Typography>
                    <ArrowRightAlt className={sideButton} />
                  </Box>
                </CardContent>
              </Card>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
