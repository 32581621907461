const userInitials = (firstNameOrFullName, lastName = null) => {
  if (!firstNameOrFullName && !lastName) return null;
  let firstName = firstNameOrFullName;
  if (lastName === null) {
    [firstName, lastName] = firstNameOrFullName.split(" ");
  }

  let firstNameInitial, lastNameInitial;
  if (firstName) {
    firstNameInitial = firstName.substring(0, 1).toUpperCase();
  }
  if (lastName) {
    lastNameInitial = lastName.substring(0, 1).toUpperCase();
  }
  return `${firstNameInitial ?? ""}${lastNameInitial ?? ""}`;
};

export { userInitials };
