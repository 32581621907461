import React from "react";
import {
  Typography,
  IconButton,
  List,
  ListItem,
  Box,
  Divider,
  Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import styles from "./TenantData.module.scss";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import {
  CallOutlined,
  CheckBoxOutlineBlankOutlined,
  EmailOutlined,
  LanguageOutlined,
  PhoneIphoneOutlined,
} from "@material-ui/icons";

export default function TenantData({ tenant, onEditModeSwitch }) {
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <Box className={styles.header}>
        <Typography variant="h6" color="textSecondary">
          {t("tenant")}
        </Typography>
        <Tooltip title={"Edit"}>
          <IconButton onClick={onEditModeSwitch}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider light />
      <Box display="flex" width="100%" padding={"1em"} flexDirection="column">
        <Box display="flex" width="100%">
          <Grid item xs={6} style={{ marginRight: "1em" }}>
            <List>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    First Name
                  </Typography>
                  <Typography variant="subtitle2">
                    {tenant?.firstName}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Last Name
                  </Typography>
                  <Typography variant="subtitle2">
                    {tenant?.lastName}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Salutation
                  </Typography>
                  <Typography variant="subtitle2">
                    {tenant?.salutation}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Remarks
                  </Typography>
                  <Typography variant="subtitle2">{tenant?.remarks}</Typography>
                </Box>
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Language
                  </Typography>
                  <Typography variant="subtitle2">
                    {tenant?.preferedLanguage}
                  </Typography>
                </Box>
                <LanguageOutlined className={styles.icon} />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Email
                  </Typography>
                  <Typography variant="subtitle2">{tenant?.email}</Typography>
                </Box>
                <EmailOutlined className={styles.icon} />
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Phone
                  </Typography>
                  <Typography variant="subtitle2">
                    {tenant?.phone} m²
                  </Typography>
                </Box>
                <PhoneIphoneOutlined className={styles.icon} />
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Phone Professional
                  </Typography>
                  <Typography variant="subtitle2">
                    {tenant?.phoneProfessional}
                  </Typography>
                </Box>
                <CallOutlined className={styles.icon} />
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column" m={1.5} my={1}>
                  <Typography variant="caption" color="textSecondary">
                    Phone Mobile
                  </Typography>
                  <Typography variant="subtitle2">
                    {tenant?.phoneMobile}
                  </Typography>
                </Box>
                <PhoneIphoneOutlined className={styles.icon} />
              </ListItem>
            </List>
          </Grid>
        </Box>
        <Grid item xs={12}>
          <ListItem className={styles.listItem}>
            <Box display="flex" flexDirection="column" m={1.5} my={1}>
              <Typography variant="caption" color="textSecondary">
                Unit
              </Typography>
              <Typography variant="subtitle2">{tenant?.unit?.label}</Typography>
            </Box>
            <CheckBoxOutlineBlankOutlined className={styles.icon} />
          </ListItem>
        </Grid>
      </Box>
    </Box>
  );
}
