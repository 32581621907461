import React, { useState, useContext, Fragment } from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Link,
  IconButton,
  Avatar,
  Fab,
  Chip,
} from "@material-ui/core";
import {
  ThumbUpAltRounded,
  MoreVert,
  TextsmsOutlined,
  ShareOutlined,
  ThumbUpAltOutlined,
} from "@material-ui/icons";
import styles from "./Post.module.scss";
import condeoApi from "../../../common/condeoApi";
import { store } from "../../../store";
import notificationAction from "../../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import { userInitials } from "../../../common/utils";
import Comments from "../../../components/Post/Comments/Comments";
import Likes from "../../../components/Post/Likes/Likes";

export default function Post({ post, handlePostDetails }) {
  const { state, dispatch } = useContext(store);
  const token = state?.auth?.token;
  const user = state?.user;
  const { t } = useTranslation();
  const [postLikes, setPostLikes] = useState(null);
  const [postComments, setPostComments] = useState(null);
  const [likesDialogState, setLikesDialogState] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [likesCount, setLikesCount] = useState(post?.likesCount);
  const [commentsCount, setCommentsCount] = useState(post?.commentsCount);
  const [liked, setLiked] = useState(post?.liked);

  const fetchPostLikes = (post) => {
    condeoApi
      .fetchPinboardPostLikes(token, post)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setPostLikes(response);
              setLikesCount(response?.length);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchPostComments = (post) => {
    condeoApi
      .fetchPinboardPostComments(token, post)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setPostComments(response);
              setCommentsCount(response?.length);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const handleCommentSection = (event, post) => {
    event.preventDefault();
    setExpanded(!expanded);
    fetchPostComments(post);
  };

  const handlePostLikes = (event, post) => {
    event.preventDefault();
    fetchPostLikes(post);
    setLikesDialogState(!!post);
  };

  const handleLikesDialogClose = (event) => {
    event.preventDefault();
    setLikesDialogState(false);
  };

  const handleCommentChange = (event) => {
    event.preventDefault();
    setCommentValue(event.target.value);
  };

  const addPostComment = (event, post) => {
    const model = {
      userId: user?.id,
      feedPostId: post?.id,
      content: commentValue,
    };
    condeoApi
      .createPinboardPostComment(token, model)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.general"),
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            setCommentValue("");
            fetchPostComments(post);
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const addPostLike = (event, post) => {
    event.preventDefault();
    const model = {
      userId: user?.id,
      postId: post?.id,
    };
    condeoApi
      .createPinboardPostLike(model, token)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.general"),
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            fetchPostLikes(post);
            setLiked(true);
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const deletePostLike = (event, post) => {
    event.preventDefault();
    const model = {
      feedPostId: post?.id,
    };
    condeoApi
      .deletePinboardPostLike(model, token)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            fetchPostLikes(post);
            setLiked(false);
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const getPostLabel = (state) => {
    if (state === "draft") {
      return <Chip size="small" label={t("draft")} variant="outlined" />;
    } else if (state === "archived") {
      return <Chip size="small" label={t("archived")} variant="outlined" />;
    }
  };

  const deletePostComment = (id, post) => {
    condeoApi
      .deletePostComment(id, token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              fetchPostComments(post);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  return (
    <React.Fragment key={post.id}>
      <Card className={styles.post} key={post.id}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={styles.avatar}>
              {post?.isOrganization
                ? userInitials(post?.organizationName)
                : userInitials(
                    post?.createdBy?.name,
                    post?.createdBy?.lastName
                  )}
            </Avatar>
          }
          action={
            <div>
              {getPostLabel(post?.state)}
              <IconButton
                aria-label="settings"
                size="small"
                onClick={(event) => handlePostDetails(event, post)}
              >
                <MoreVert />
              </IconButton>
            </div>
          }
          title={
            <Link className={styles.pointerCursor}>
              {post?.isOrganization
                ? post?.organizationName
                : `${post?.createdBy.name} ${post?.createdBy.lastName}`}
            </Link>
          }
          subheader={post?.createdAt}
        ></CardHeader>
        <Divider />
        <CardContent className={styles.postContentContainer}>
          <Typography style={{ whiteSpace: "break-spaces" }}>
            {post?.content}
          </Typography>
        </CardContent>
        {(likesCount > 0 || commentsCount > 0) && (
          <div className={styles.postReactionsContainer}>
            {likesCount > 0 && (
              <Fragment>
                <Link
                  href="#"
                  variant="body2"
                  className={styles.postReactionItem}
                  onClick={(event) => handlePostLikes(event, post)}
                >
                  <Fab
                    color="secondary"
                    aria-label="add"
                    size="small"
                    className={styles.likeFab}
                  >
                    <ThumbUpAltRounded
                      size="small"
                      className={styles.cardActionsIcon}
                    />
                  </Fab>
                  <Typography>{likesCount}</Typography>
                </Link>
              </Fragment>
            )}
            {commentsCount > 0 && (
              <Link
                href="#"
                className={styles.postReactionItem}
                onClick={(event) => handleCommentSection(event, post)}
              >
                <Typography>
                  {commentsCount} {t("comments")}
                </Typography>
              </Link>
            )}
          </div>
        )}
        <Divider />
        {post.state === "published" && (
          <CardActions>
            <Button
              variant="text"
              startIcon={
                liked ? (
                  <ThumbUpAltRounded color="secondary" />
                ) : (
                  <ThumbUpAltOutlined color="primary" />
                )
              }
              className={styles.noTextTransformButton}
              onClick={(event) =>
                liked ? deletePostLike(event, post) : addPostLike(event, post)
              }
            >
              <Typography className={liked ? styles.activeText : null}>
                {t("like")}
              </Typography>
            </Button>
            <Button
              variant="text"
              onClick={(event) => handleCommentSection(event, post)}
              startIcon={<TextsmsOutlined />}
              className={styles.noTextTransformButton}
            >
              <Typography>{t("comment")}</Typography>
            </Button>
            <Button
              variant="text"
              startIcon={<ShareOutlined />}
              className={styles.noTextTransformButton}
            >
              <Typography>{t("share")}</Typography>
            </Button>
          </CardActions>
        )}
        <Comments
          expanded={expanded}
          addPostComment={addPostComment}
          deletePostComment={deletePostComment}
          postComments={postComments}
          post={post}
          handleCommentChange={handleCommentChange}
          commentValue={commentValue}
        />
      </Card>
      <Likes
        postLikes={postLikes}
        dialogState={likesDialogState}
        onClose={handleLikesDialogClose}
      />
    </React.Fragment>
  );
}
