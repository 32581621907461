/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
  Fragment,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import condeoApi from "../../common/condeoApi";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  CardHeader,
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  Menu,
} from "@material-ui/core";
import {
  BackupOutlined,
  MoreVert,
  ErrorOutline,
  DeleteOutline,
  Close,
  GetAppOutlined,
  DescriptionOutlined,
} from "@material-ui/icons";
import styles from "./Documents.module.scss";
import { useDropzone } from "react-dropzone";
import { store } from "../../store";
import selectedDocumentAction from "../../store/actions/selectedDocumentAction";
import notificationAction from "../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../common/Dialogs/DeleteDialog/DeleteDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px",
    width: "calc(33.3333% - 20px)",
    cursor: "pointer",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  icon: {
    minWidth: "35px",
  },
}));

export default function Documents(props) {
  const [documents, setDocuments] = useState(null);
  const token = localStorage.getItem("auth_token");
  const { dispatch } = useContext(store);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogState, setDialogState] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    uploadDocuments(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    fetchDocuments();
    // document.title = "Documents";
  }, []);

  const fetchDocuments = () => {
    condeoApi
      .fetchDocuments(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setDocuments(response.documents);
            } else {
              setDocuments(null);
            }
          });
        }
      })
      .catch((err) => {
        setDocuments(null);
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const uploadDocuments = (files) => {
    const model = {
      documents: files,
    };
    condeoApi
      .addDocuments(token, model)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              setTimeout(() => {
                fetchDocuments();
              }, 1000);
            }
          });
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const handleDocumentDetails = (document) => {
    history.push(`documents/${document.id}/details`);
    dispatch(
      selectedDocumentAction({
        selectedDocument: document,
      })
    );
  };
  const handleDeleteDialog = () => {
    setDialogState(true);
    setAnchorEl(null);
  };

  const getIconByFileType = (fileName) => {
    const type = fileName
      ?.toLowerCase()
      ?.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    let icon = <></>;
    switch (type) {
      case "pdf":
        icon = (
          <img
            alt="document-file"
            src={require("../../assets/Images/Files/pdf-icon.png")}
          />
        );
        break;
      case "doc":
      case "docx":
      case "docm":
        icon = (
          <img
            alt="document-file"
            src={require("../../assets/Images/Files/word-icon.png")}
          />
        );
        break;
      case "ppt":
      case "pptx":
      case "pps":
        icon = (
          <img
            alt="document-file"
            src={require("../../assets/Images/Files/powerpoint-icon.png")}
          />
        );
        break;
      case "xlsx":
        icon = (
          <img
            alt="document-file"
            src={require("../../assets/Images/Files/exel-icon.png")}
          />
        );
        break;
      case "txt":
        icon = (
          <img
            alt="document-file"
            src={require("../../assets/Images/Files/text-icon.png")}
          />
        );
        break;
      case "png":
      case "jpg":
      case "jpeg":
        icon = (
          <img
            alt="document-file"
            src={require("../../assets/Images/Files/image-icon.png")}
          />
        );
        break;
      case "mp3":
        icon = (
          <img
            alt="document-file"
            src={require("../../assets/Images/Files/mp3-icon.png")}
          />
        );
        break;
      case "wav":
      case "mp4":
      case "wma":
        icon = (
          <img
            alt="document-file"
            src={require("../../assets/Images/Files/image-icon.png")}
          />
        );
        break;
      default:
        icon = <DescriptionOutlined />;
    }
    return icon;
  };
  const handleDocumentDetailsMenu = (event, document) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedDocument(document);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDialogState(false);
  };
  const deleteDocument = () => {
    condeoApi
      .deleteDocument(token, selectedDocument?.id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              dispatch(
                notificationAction({
                  open: true,
                  message: "Document has been deleted successfully",
                })
              );
              setAnchorEl(null);
              setDialogState(!response);
              fetchDocuments();
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const handleFileDownload = () => {
    condeoApi
      .getDocument(token, selectedDocument)
      .then((result) => {
        if (result.ok) {
          return result.blob();
        }
      })
      .then((blob) => {
        var csvURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", selectedDocument?.fileName);
        tempLink.click();
        tempLink.remove();
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  return (
    <Fragment>
      <Box className={styles.header}>
        <Typography variant="h6">{t("documents")}</Typography>
        <Typography variant="caption" color="textSecondary">
          Use Documents to share information in files format with your Tenants
          and Collaborators.
        </Typography>
      </Box>
      <Box className={styles.documentsListContainer}>
        {documents && documents?.length === 0 && (
          <Box className={styles.emptyStateContainer}>
            <img
              alt="invitation"
              src={require("../../assets/Images/document.png")}
              style={{ maxWidth: "10em" }}
            />
            <Box mt={2}>
              <Typography variant="body2" color="textSecondary">
                {t("emptyDocuments")}
              </Typography>
            </Box>
          </Box>
        )}
        {documents?.map((document) => {
          return (
            <Card
              className={classes.root}
              key={document.id}
              onClick={() => handleDocumentDetails(document)}
            >
              <CardHeader
                avatar={getIconByFileType(document?.fileName)}
                action={
                  <IconButton
                    aria-label="settings"
                    size="small"
                    onClick={(e) => handleDocumentDetailsMenu(e, document)}
                  >
                    <MoreVert />
                  </IconButton>
                }
                title={
                  document?.name ? (
                    <Typography>{document?.name}</Typography>
                  ) : (
                    <Box className={styles.incompleteDocumentInfo}>
                      <ErrorOutline fontSize="small" />
                      <Typography variant="caption">
                        {t("moreInfoNeed")}
                      </Typography>
                    </Box>
                  )
                }
                subheader={
                  <Typography variant="caption" color="textSecondary">
                    {document?.fileName ? document?.fileName : "No attachments"}
                  </Typography>
                }
              />
            </Card>
          );
        })}
      </Box>
      <DeleteDialog
        open={dialogState}
        onDialogClose={() => setDialogState(false)}
        onDelete={() => deleteDocument(document)}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
      >
        <MenuItem onClick={() => handleFileDownload()}>
          <ListItemIcon className={classes.icon}>
            <GetAppOutlined fontSize="small" />
          </ListItemIcon>
          {t("Actions.download")}
        </MenuItem>
        <MenuItem onClick={() => handleDeleteDialog()}>
          <ListItemIcon className={classes.icon}>
            <DeleteOutline fontSize="small" />
          </ListItemIcon>
          {t("delete")}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon className={classes.icon}>
            <Close fontSize="small" />
          </ListItemIcon>
          {t("Actions.cancel")}
        </MenuItem>
      </Menu>
      <Box component="div" m={1}>
        <section className={styles.fileUploadContainer}>
          <div {...getRootProps({ className: styles.dropZone })}>
            <input
              {...getInputProps()}
              multiple
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            />
            <div className={styles.fileUploadInfo}>
              <Typography>{t("dragFiles")}</Typography>
              <BackupOutlined
                id="animated-example"
                className={styles.downloadIcon}
              />
            </div>
          </div>
        </section>
      </Box>
    </Fragment>
  );
}
