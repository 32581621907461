import React, { useState, useContext } from 'react'
import {
  content,
  container,
  invitationContent,
  email,
  invitationHeader,
  invitationFooter,
} from './InvitationPage.module.scss'
import {
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Button,
  Table,
  TableHead,
  Typography,
  Container,
  Card,
} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import { store } from '../../../../store'
import { useHistory } from 'react-router-dom'
import condeoApi from '../../../../common/condeoApi'
import DoneIcon from '@material-ui/icons/Done'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import notificationAction from '../../../../store/actions/notificationAction'
export default function InvitationPage() {
  const globalState = useContext(store)
  const [invitationList, addInvitation] = useState([])
  const { dispatch } = globalState
  const history = useHistory()
  const { register, handleSubmit, errors } = useForm()
  const user = JSON.parse(localStorage.getItem('user'))
  const token = localStorage.getItem('auth_token')
  const { t } = useTranslation()

  localStorage.setItem('activeStep', 1)
  const hasError = (inputName) => !!(errors && errors[inputName])

  const handleHomeNavigation = () => {
    history.push('/setup-completed')
  }
  const onSubmit = (data) => {
    const model = {
      email: data.email,
      isAdmin: true,
      onBoarding: true
    }
    condeoApi
      .inviteUser(model, user.organization?.id, token)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: response.message,
              })
            )
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t('Errors.401'),
              })
            )
          } else {
            addInvitation([...invitationList, model])
            dispatch(
              notificationAction({
                open: true,
                message: t('Organization.invitationSuccess'),
              })
            )
          }
        })
      })
      .catch((err) => {
        console.log('Could not communicate with the server!', err.message)
        dispatch(
          notificationAction({
            open: true,
            message: t('Errors.general'),
          })
        )
      })
  }
  return (
    <Container className={container}>
      <Card className={content}>
        <div className={invitationHeader}>
          <Typography>{t('Organization.invite')}</Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={invitationContent}>
            <TextField
              variant='outlined'
              margin='normal'
              key='email'
              id='email'
              label='Email Address'
              name='email'
              className={email}
              inputRef={register({
                required: 'Email Address is required!',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email address',
                },
              })}
              error={hasError('email')}
              helperText={hasError('email') ? `${errors.email.message}` : null}
            />
          </div>
          <div className={invitationFooter}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              endIcon={<SendIcon />}
            >
              {t('Organization.sendInvitation')}
            </Button>

            {invitationList.length > 0 && invitationList !== undefined ? (
              <Button
                variant='outlined'
                onClick={handleHomeNavigation}
                style={{ marginLeft: '5px' }}
                color='primary'
                startIcon={<DoneIcon />}
              >
                {t('Organization.allSet')}
              </Button>
            ) : (
                <Button onClick={handleHomeNavigation}>
                  {t('Organization.skipStep')}
                </Button>
              )}
          </div>
        </form>
      </Card>
      {invitationList.length > 0 && invitationList !== undefined && (
        <Table style={{ border: '1px solid #e4e4e4', width: '740px' }} id='invitationTable'>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{t('User.name')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('User.admin')}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitationList.map((user, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.isAdmin.toString()}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
    </Container>
  )
}
