import React, { useContext, useRef, useEffect } from 'react'
import {
  TextField,
  Button,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core'
import { Formik } from 'formik'
import { useFormik } from 'formik'
import styles from './OrganizationSetup.module.scss'
import SaveIcon from '@material-ui/icons/Save'
import notificationAction from '../../store/actions/notificationAction'
import { store } from '../../store'
import { Switch, Route } from 'react-router-dom'
import CustomizedStepper from '../../common/Stepper/Stepper'
import InvitationPage from '../../views/OrganizationSetup/OrganizationInvitation/InvitationPage/InvitationPage'
import condeoApi from '../../common/condeoApi'
import userAction from '../../store/actions/userAction'
import Address from '../../components/Address/Address'
import { useTranslation } from 'react-i18next'
import loginAction from '../../store/actions/loginAction';
import HeaderLight from '../../components/HeaderLight/HeaderLight'

export default function OrganizationSetup(props) {
  const globalState = useContext(store)
  const { state, dispatch } = globalState
  const { user } = state
  const token = localStorage.getItem('auth_token')
  const stepperRef = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    if (user && user.onBoarding) {
    }
  }, [props, user])

  const fetchUser = (token) => {
    condeoApi.fetchUser(token).then((result) => {
      result.json().then((response) => {
        if (response.id) {
          dispatch(
            userAction({ user: response })
          )
          if (response.organization.id) {
            // history.push('/setup-organization/invite')
          }
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: response.message,
            })
          )
        }
      })
    })
  }
  const createOrganization = (values) => {
    const model = {
      ownerId: user.id,
      name: values.name,
      onBoardingCompleted: true,
      address: {
        street: values.street,
        additionalStreet: values.additionalStreet,
        streetNumber: values.streetNumber,
        additionalStreetNumber: values.additionalStreetNumber,
        place: values.place,
        poBox: values.poBox,
        canton: values.canton,
        postCode: values.postCode,
        country: values.country,
      },
    }
    condeoApi
      .addOrganization(model, token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              fetchUser(response.token)
              stepperRef.current.handleNextStep()
              dispatch(
                notificationAction({
                  open: true,
                  message: t('Organization.success'),
                })
              )
              dispatch(
                loginAction({
                  token: response.token,
                })
              )
              localStorage.setItem('activeStep', 1)
            }
          })
        } else if (result.status === 401) {
          dispatch(
            notificationAction({
              open: true,
              message: t('Errors.401'),
            })
          )
        }
      })
      .catch((err) => {
        console.log('Could not communicate with the server!', err.message)
        dispatch(
          notificationAction({
            open: true,
            message: t('Errors.general'),
          })
        )
      })
  }
  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'This field is Required'
    }
    return errors
  }
  const formik = useFormik({
    initialValues: {
      name: '',
      street: '',
      streetNumber: '',
      additionalStreetNumber: '',
      additionalStreet: '',
      place: '',
      poBox: '',
      postCode: '',
      canton: '',
      country: '',
    },
    validate,
    onSubmit: (values) => {
      createOrganization(values)
    },
  })
  const addOrganizationView = () => {
    return (
      <Container className={styles.content}>
        <Card>
          <CardContent>
            <Typography component='h1' variant='h5' className={styles.formHeader}>
              Organization Setup{' '}
            </Typography>
            <Formik enableReinitialize={true}>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.gridContent}>
                  <Grid container spacing={3}>
                    <div className={styles.addFormsContainer}>
                      <Grid item xs={12} style={{ maxHeight: '90px' }}>
                        <div className={styles.textFieldMinHeight}>
                          <TextField
                            id='name'
                            variant='outlined'
                            label='Name'
                            name='name'
                            size='small'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            fullWidth
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={styles.error}>
                              {formik.errors.name}
                            </div>
                          ) : (
                              ''
                            )}
                        </div>
                      </Grid>
                      <Address formik={formik} />
                    </div>
                  </Grid>
                </div>
                <div className={styles.saveButtonContainer}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    size='medium'
                    className={styles.saveOrganizationButton}
                    startIcon={<SaveIcon />}
                  >
                    Save and continue
                  </Button>
                </div>
              </form>
            </Formik>
          </CardContent>
        </Card>
      </Container>
    )
  }

  return (
    <div className={styles.container}>
      <HeaderLight user={user} />
      <CustomizedStepper ref={stepperRef} />
      <Switch>
        <Route exact path='/setup-organization' render={addOrganizationView} />
        <Route
          exact
          path='/setup-organization/invite'
          component={InvitationPage}
        />
      </Switch>
    </div>
  )
}
