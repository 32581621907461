import React from "react";
import {
  Typography,
  IconButton,
  List,
  ListItem,
  Box,
  Divider,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import styles from "./PropertyData.module.scss";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import AddressData from "../../../../../components/Address/AddressData/AddressData";

export default function PropertyData({ id, property, onEditModeSwitch }) {
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <Box className={styles.header}>
        <Typography variant="h6" color="textSecondary">
          {t("AllProperties.property")}
        </Typography>
        <IconButton onClick={onEditModeSwitch}>
          <EditIcon />
        </IconButton>
      </Box>
      <Divider light />
      <Grid container spacing={3}>
        <Box width="100%">
          <Box p={"1em"} mb={"-1em"} mt={"1em"}>
            <List>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" color="textSecondary">
                    Label
                  </Typography>
                  <Typography variant="body2">{property?.label}</Typography>
                </Box>
              </ListItem>
              <ListItem className={styles.listItem}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" color="textSecondary">
                    Description
                  </Typography>
                  <Typography variant="subtitle2">
                    {property?.description}
                  </Typography>
                </Box>
              </ListItem>
            </List>
          </Box>
          <AddressData address={property?.address} id={id} />
        </Box>
      </Grid>
    </Box>
  );
}
