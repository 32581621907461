import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Card,
  Typography,
  TextField,
  Grid,
  Link,
  Button,
  Container,
  CardContent
} from '@material-ui/core'
import { card, container, submitButton, gridMargin } from '../auth.module.scss'
import { useQueryParams } from 'hookrouter'
import { store } from '../../store'
import notificationAction from '../../store/actions/notificationAction'
import { useForm } from 'react-hook-form'
import condeoApi from '../../common/condeoApi'
export default function SetPasswordPage() {
  const globalState = useContext(store)
  const { dispatch } = globalState

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setError,
    clearError,
  } = useForm()

  const hasError = (inputName) => !!(errors && errors[inputName])

  const watchAllFields = watch()

  const handle = () => {
    if (
      errors.confirmPassword.type === 'required' ||
      errors.confirmPassword.type === 'notMatch'
    ) {
      return `${errors.confirmPassword.message}`
    }
  }

  const history = useHistory()
  const queryParams = useQueryParams()
  const onSubmit = (data) => {
    const model = {
      email: queryParams[0].email,
      resetToken: queryParams[0].resetToken,
      password: data.password,
    }
    condeoApi
      .setPassword(model)
      .then((result) => {
        result.json().then((response) => {
          if (response.success) {
            history.push('/login')
          } else {
            dispatch(
              notificationAction({
                open: true,
                message: response.message
              })
            )
          }
        })
      })
      .catch((err) => {
        console.log('Could not communicate with server!', err.message)
        dispatch(
          notificationAction({
            open: true,
            message: 'Something went wrong!',
          })
        )
      })
  }
  return (
    <Container className={container}>
      <Card className={card}>
        <CardContent>
          <Typography component='h1' variant='h5'>
            Set Password
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                name='password'
                label='password'
                type='password'
                id='password'
                size='small'
                inputRef={register({
                  required: 'Password is required!',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters.',
                  },
                  notMatch: {},
                })}
                error={hasError('password')}
                helperText={
                  hasError('password') ? `${errors.password.message}` : null
                }
              />
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                name='confirmPassword'
                label='confirm password'
                type='password'
                id='confirmPassword'
                size='small'
                onChange={() => {
                  if (
                    watchAllFields.password !== watchAllFields.confirmPassword
                  ) {
                    setError(
                      'confirmPassword',
                      'notMatch',
                      'Password does not match'
                    )
                  } else clearError('confirmPassword')
                }}
                inputRef={register({
                  required: 'Confirm Password is required!',
                })}
                error={hasError('confirmPassword')}
                helperText={hasError('confirmPassword') ? handle() : null}
              />
              <div className={submitButton}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  size='small'
                >
                  Set Password
                </Button>
              </div>
              <Grid container className={gridMargin}>
                <Grid item>
                  <Link href='/login' variant='body2'>
                    {'Back to login'}
                  </Link>
                </Grid>
              </Grid>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}
