import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Typography,
  TextField,
  Button,
  Container,
  CardContent,
  CardHeader,
  Divider,
} from "@material-ui/core";
import { container, submitButton, card } from "../../../auth.module.scss";
import { useQueryParams } from "hookrouter";
import { store } from "../../../../store";
import notificationAction from "../../../../store/actions/notificationAction";
import { useForm } from "react-hook-form";
import condeoApi from "../../../../common/condeoApi";

export default function ConfirmAccountPage(props) {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const firstName = params.get("firstName");
  const lastName = params.get("lastName");
  const email = params.get("email");
  const [tenantEmail] = useState(email);
  const [tenantFirstName, setTenantFirstName] = useState(firstName);
  const [tenantLastName, setTenantLastName] = useState(lastName);

  const globalState = useContext(store);
  const { dispatch } = globalState;

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setError,
    clearError,
  } = useForm();

  const hasError = (inputName) => !!(errors && errors[inputName]);
  const watchAllFields = watch();

  const handle = () => {
    if (
      errors.confirmPassword.type === "required" ||
      errors.confirmPassword.type === "notMatch"
    ) {
      return `${errors.confirmPassword.message}`;
    }
  };

  const queryParams = useQueryParams();
  const history = useHistory();

  const onSubmit = (data) => {
    const model = {
      firstName: tenantFirstName.split("-").join(" "),
      lastName: tenantLastName.split("-").join(" "),
      email: props.email ? props.email : queryParams[0].email,
      password: data.password,
      invitationToken: props.invitationToken
        ? props.invitationToken
        : queryParams[0].invitationToken,
    };
    condeoApi.confirmTenantAccount(model).then((result) => {
      result.json().then((response) => {
        if (response.success) {
          dispatch(
            notificationAction({
              open: true,
              message:
                "Your password is successfully set! Please check your email",
            })
          );
          history.push("/tenant-invitation");
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: response.message,
            })
          );
        }
      });
    });
  };

  return (
    <Container className={container}>
      <Card className={card}>
        <CardHeader
          title="Confirm Account"
          subheader={
            <Typography variant="body2" color="textSecondary">
              Fill out the form to complete your profile
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                label="Email"
                type="email"
                disabled
                value={tenantEmail}
                id="email"
                size="small"
                inputRef={register({
                  required: "Email Address is required!",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={hasError("email")}
                helperText={
                  hasError("email") ? `${errors.email.message}` : null
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="firstName"
                label="Name"
                type="text"
                value={tenantFirstName?.split("-").join(" ")}
                onChange={(e) => setTenantFirstName(e.target.value)}
                id="firstName"
                size="small"
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="lastName"
                label="Last Name"
                type="text"
                value={tenantLastName?.split("-").join(" ")}
                onChange={(e) => setTenantLastName(e.target.value)}
                id="lastName"
                size="small"
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                size="small"
                inputRef={register({
                  required: "Password is required!",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters.",
                  },
                  notMatch: {},
                })}
                error={hasError("password")}
                helperText={
                  hasError("password") ? `${errors.password.message}` : null
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                size="small"
                onFocus={() => {
                  if (
                    watchAllFields.password !== watchAllFields.confirmPassword
                  ) {
                    setError(
                      "confirmPassword",
                      "notMatch",
                      "Password does not match"
                    );
                  } else clearError("confirmPassword");
                }}
                onChange={() => {
                  if (
                    watchAllFields.password !== watchAllFields.confirmPassword
                  ) {
                    setError(
                      "confirmPassword",
                      "notMatch",
                      "Password does not match"
                    );
                  } else clearError("confirmPassword");
                }}
                inputRef={register({
                  required: "Confirm Password is required!",
                  validate: (value) => value === watchAllFields.password,
                })}
                error={hasError("confirmPassword")}
                helperText={hasError("confirmPassword") ? handle() : null}
              />
              <div className={submitButton}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Complete profile
                </Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}
