/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, Fragment } from "react";
import { Typography, Divider, Paper, Grid, Box } from "@material-ui/core";
import Tenants from "../../../Tenants/Tenants";
import styles from "./UnitDetails.module.scss";
import { store } from "../../../../../store";
import condeoApi from "../../../../../common/condeoApi";
import notificationAction from "../../../../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import UnitData from "./UnitData/UnitData";
import UnitForm from "./UnitForm/UnitForm";
export default function UnitDetails(props) {
  const { dispatch, state } = useContext(store);
  const [unit, setUnit] = useState(null);
  const { t } = useTranslation();
  const { id } = props.match.params;
  const { token } = state.auth;
  const [editingMode, setEditingMode] = useState(false);

  useEffect(() => {
    fetchUnit(token, id);
  }, [editingMode]);

  const fetchUnit = () => {
    condeoApi
      .fetchUnit(token, id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setUnit(response);
            }
          });
        } else {
          console.log("Something went wrong while fetching units.");
          dispatch(
            notificationAction({
              open: true,
              message: "Something went wrong while fetching units.",
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  return (
    <Fragment>
      <Box className={styles.header}>
        <Typography variant="h6">{t("Units.details")}</Typography>
      </Box>
      <Divider />
      <Grid container direction="row" spacing={3} className={styles.container}>
        <Grid item xs={6}>
          <Paper>
            {editingMode ? (
              <UnitForm
                unit={unit}
                onEditModeSwitch={() => setEditingMode(!editingMode)}
              />
            ) : (
              <UnitData
                unit={unit}
                onEditModeSwitch={() => setEditingMode(!editingMode)}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={6} className={styles.formContainer}>
          <Paper>
            <Box p={2} mt={"20px"}>
              <Tenants unitId={id} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}
