import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import styles from './TennatInvitation.module.scss'
export default function TenantInvitation() {
  return (
    <div className={styles.container}>
      <div className={styles.allSetImageContainer}>
        <img
          alt='invitation'
          src={require('../../../../assets/Images/documentsImage.png')}
          className={styles.allSetImage}
        />
      </div>
      <div className={styles.userOnboardingContainer}>
        <Typography variant='h6' className={styles.headerText}>All set! Please continue by using the mobile app. </Typography>
        <div className={styles.downloadOptions}>
          <div className={styles.storeImageContainer}>
            <a target="blank" href="https://www.apple.com/ios/app-store/" >
              <img
                alt='invitation'
                src={require('../../../../assets/Images/app-store.png')}
                className={styles.storeImage}
              />
            </a>
            <a target="blank" href="https://www.apple.com/ios/app-store/" >
              <img
                alt='invitation'
                src={require('../../../../assets/Images/google-play.png')}
                className={styles.storeImage}
              />
            </a>
          </div>
          <div className={styles.divider}>
            <span className={styles.dividerSpan}>OR</span>
          </div>
          <div className={styles.qrCodeSection}>
            <div className={styles.footer}>
              <Paper className={styles.qrCodeContainer}>
                <Typography variant='subtitle2'
                  className={styles.qrCodeGuideText}
                >Scan this QR code to get the app </Typography>
                <img
                  alt='invitation'
                  src={require('../../../../assets/Images/qr-code.jpg')}
                  className={styles.qrCode}
                  style={{ maxWidth: '130px' }}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
