import React, { useContext } from "react";
import { Menu, ListItemIcon, MenuItem } from "@material-ui/core";
import { store } from "../../../store";
import { Close, DeleteOutline, Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: "35px",
  },
}));

function PostMenu({ editingPost, onDelete, anchorEl, onEdit, onClose }) {
  const classes = useStyles();
  const { state } = useContext(store);
  const user = state?.user;
  const { t } = useTranslation();
  return (
    <Menu
      id="post-actions-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      elevation={1}
    >
      {editingPost?.createdBy?.id === user?.id && (
        <MenuItem onClick={(event) => onEdit(event, "edit")}>
          <ListItemIcon className={classes.icon}>
            <Edit fontSize="small" />
          </ListItemIcon>
          {t("Actions.edit")}
        </MenuItem>
      )}
      <MenuItem onClick={() => onDelete()}>
        <ListItemIcon className={classes.icon}>
          <DeleteOutline fontSize="small" />
        </ListItemIcon>
        {t("delete")}
      </MenuItem>
      <MenuItem onClick={onClose}>
        <ListItemIcon className={classes.icon}>
          <Close fontSize="small" />
        </ListItemIcon>
        {t("Actions.cancel")}
      </MenuItem>
    </Menu>
  );
}

export default PostMenu;
