import {
  Avatar,
  Card,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useState, Fragment } from "react";
import { ChevronLeftOutlined, Close, Edit } from "@material-ui/icons";
import { store } from "../../store";
import styles from "./Profile.module.scss";
import condeoApi from "../../common/condeoApi";
import notificationAction from "../../store/actions/notificationAction";
import userAction from "../../store/actions/userAction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { userInitials } from "../../common/utils";
import ProfileData from "./ProfileData/ProfileData";
import ProfileForm from "./ProfileForm/ProfileForm";

function Profile() {
  const { state, dispatch } = useContext(store);
  const { user } = state;
  const { token } = state.auth;
  const [editingMode, setEditingMode] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const editUser = (values) => {
    if (values) {
      const model = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        organizationName: values?.organizationName,
        id: user?.id,
      };
      condeoApi
        .updateUser(token, model)
        .then((result) => {
          result.json().then((response) => {
            if (!response.success) {
              dispatch(
                notificationAction({
                  open: true,
                  message: "Something went wrong.",
                })
              );
            } else {
              dispatch(
                notificationAction({
                  open: true,
                  message: `Data updated successfully`,
                })
              );
              setEditingMode(!editingMode);
              dispatch(
                userAction({
                  user: response.user,
                })
              );
            }
          });
        })
        .catch((err) => {
          console.log("Could not communicate with the server!", err.message);
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        });
    }
  };

  return (
    <Fragment>
      <Button
        onClick={() => history.goBack()}
        startIcon={<ChevronLeftOutlined fontSize="large" />}
      >
        {t("Actions.back")}
      </Button>
      <Card className={styles.container}>
        <Box p={3} className={styles.cardHeader}>
          <Avatar className={styles.avatar}>
            <Typography variant="h5">
              {userInitials(user?.name, user?.lastName)}
            </Typography>
          </Avatar>
          <Typography variant="h6">
            {user?.name} {user?.lastName}
          </Typography>
          <Typography color="textSecondary">
            {user?.organization?.name}
          </Typography>
        </Box>
        <Divider variant="middle" light />
        <Box display="flex" justifyContent="flex-end" ml={4} mr={4}>
          <Tooltip title={editingMode ? "Cancel" : "Edit"}>
            <IconButton edge="end" onClick={() => setEditingMode(!editingMode)}>
              {editingMode ? <Close /> : <Edit />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box mx={4}>
          {editingMode ? (
            <ProfileForm editUser={(data) => editUser(data)} />
          ) : (
            <ProfileData />
          )}
        </Box>
      </Card>
    </Fragment>
  );
}

export default Profile;
