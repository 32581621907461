/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Box,
  CircularProgress,
} from "@material-ui/core";
import styles from "./SocialFeed.module.scss";
import IconButton from "@material-ui/core/IconButton";
import {
  ImageOutlined,
  AttachFileOutlined,
  LocationOnOutlined,
} from "@material-ui/icons";
import condeoApi from "../../common/condeoApi";
import { store } from "../../store";
import notificationAction from "../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Formik } from "formik";
import Post from "./Post/Post";
import DeleteDialog from "../../common/Dialogs/DeleteDialog/DeleteDialog";
import PostCreateDialog from "../../components/PostCreateDialog/PostCreateDialog";
import PostMenu from "../../components/Post/PostMenu/PostMenu";

export default function SocialFeed(props) {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(store);
  const token = state?.auth?.token;
  const user = state?.user;

  const [anchorEl, setAnchorEl] = useState(null);
  const [posts, setPosts] = useState(null);
  const [editingPost, setEditingPost] = useState(null);
  const [deleteDialogState, setDeleteDialogState] = useState(false);
  const [deletingPost, setDeletingPost] = useState(null);
  const [dialogState, setDialogState] = useState(false);
  const [postState, setPostState] = useState("adding");

  useEffect(() => {
    fetchPosts();
  }, []);

  const handlePostCreate = (
    event,
    postState,
    selectedProperties,
    content,
    postAsOrganization
  ) => {
    event.preventDefault();
    const model = {
      content: content?.trim(),
      createdBy: user?.id,
      state: postState,
      properties: selectedProperties,
      isOrganization: postAsOrganization,
      organizationId: user?.organization.id,
    };
    if (
      postState !== "draft" &&
      (!selectedProperties || selectedProperties?.length === 0)
    ) {
      return dispatch(
        notificationAction({
          open: true,
          message: "Please select a property",
        })
      );
    }
    condeoApi
      .createFeedPost(model, token)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            if (result.status === 401) {
              dispatch(
                notificationAction({
                  open: true,
                  message: t("Errors.401"),
                })
              );
            } else {
              dispatch(
                notificationAction({
                  open: true,
                  message: t("Errors.general"),
                })
              );
            }
          } else {
            dispatch(
              notificationAction({
                open: true,
                message: `Post has been added successfully`,
              })
            );
            setPosts([response?.createdPost, ...posts]);
            setDialogState(false);
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchPosts = () => {
    condeoApi
      .fetchFeedPosts(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response?.posts) {
              setPosts(response?.posts);
            } else {
              setPosts([]);
            }
          });
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: "Could not fetch posts.",
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const deletePost = () => {
    condeoApi
      .deleteFeedPost(token, deletingPost?.id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              setAnchorEl(null);
              dispatch(
                notificationAction({
                  open: true,
                  message: "Post has been deleted successfully",
                })
              );
              fetchPosts();
              setDeleteDialogState(false);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePostDelete = (post) => {
    setDeletingPost(post);
    setDeleteDialogState(true);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.label) {
      errors.label = "This field is Required";
    }
    if (!values.description) {
      errors.description = "This field is Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {},
    validate,
    onSubmit: (values) => {
      handlePostCreate(values);
    },
  });

  const handleCreateDialog = () => {
    setPostState("adding");
    setDialogState(true);
    setEditingPost(null);
  };

  const handleEditDialogState = (event, post, state) => {
    setPostState("editing");
    event.preventDefault();
    setAnchorEl(null);
    setDialogState(true);
  };

  const handlePostUpdate = (
    post,
    selectedProperties,
    content,
    postAsOrganization
  ) => {
    const model = {
      id: post?.id,
      content: content,
      isOrganization: postAsOrganization,
      properties: selectedProperties,
    };
    if (
      postState !== "draft" &&
      (!selectedProperties || selectedProperties?.length === 0)
    ) {
      return dispatch(
        notificationAction({
          open: true,
          message: "Please select a property",
        })
      );
    }
    condeoApi
      .updateFeedPost(model, token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              dispatch(
                notificationAction({
                  open: true,
                  message: "Post has been updated successfully",
                })
              );
              fetchPosts();
              setAnchorEl(null);
              setDialogState(!response.success);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const handlePostDetails = (event, post) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setEditingPost(post);
    console.log("editingPost: ", post);
    setDeletingPost(post);
  };

  return (
    <Fragment>
      <Box className={styles.header}>
        <Typography variant="h6">{t("feed")}</Typography>
        <Typography variant="caption" color="textSecondary">
          Here you can post and see other posts from Tenans and other Admins of
          your organization.
        </Typography>
      </Box>
      <div className={styles.container}>
        <div className={styles.postsContainer}>
          <div>
            {state.user?.admin && (
              <Formik enableReinitialize={true}>
                <form onSubmit={formik.handleSubmit}>
                  <Card className={styles.postHeader}>
                    <CardHeader subheader={t("createPost")} />
                    <Divider />
                    <CardContent className={styles.postCardContent}>
                      <div
                        className={styles.postCreateCard}
                        onClick={() => handleCreateDialog()}
                      >
                        <Typography color="textSecondary">
                          {t("whatIsOnYourMind")}, {user?.name}?
                        </Typography>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardActions style={{ padding: "0 8px", display: "flex" }}>
                      <IconButton aria-label="add to favorites">
                        <ImageOutlined />
                      </IconButton>
                      <IconButton aria-label="share">
                        <AttachFileOutlined />
                      </IconButton>
                      <IconButton aria-label="share">
                        <LocationOnOutlined />
                      </IconButton>
                    </CardActions>
                  </Card>
                </form>
              </Formik>
            )}
          </div>
          <div className={styles.postsContainer}>
            {!posts && (
              <Box className={styles.circularProgress}>
                <CircularProgress />
              </Box>
            )}
            {posts?.length === 0 && (
              <Box className={styles.emptyStateContainer}>
                <img
                  alt="No posts"
                  src={require("../../assets/Images/posts.png")}
                  className={styles.emptyStateImg}
                />
                <Typography variant="body2" color="textSecondary">
                  There are no Social Feed posts to display. Create your first
                  post now!
                </Typography>
              </Box>
            )}
            {posts
              ?.filter((p) => p.state === "published")
              .map((p, index) => {
                return (
                  <Post
                    dialogState={dialogState}
                    key={p.id}
                    post={p}
                    index={index}
                    handlePostDetails={handlePostDetails}
                  />
                );
              })}
            <PostMenu
              editingPost={editingPost}
              deletingPost={deletingPost}
              onClose={() => handleClose()}
              onDelete={() => handlePostDelete(deletingPost)}
              onEdit={(event, postState) =>
                handleEditDialogState(event, editingPost, postState)
              }
              anchorEl={anchorEl}
              selectedItem={state?.pinboard?.selectedItem}
            />
            <PostCreateDialog
              postState={postState}
              open={dialogState}
              onDialogClose={() => setDialogState(false)}
              editingPost={editingPost}
              onCreate={handlePostCreate}
              onUpdate={handlePostUpdate}
            />
            <DeleteDialog
              open={deleteDialogState}
              onDelete={() => deletePost(deletingPost)}
              onDialogClose={() => setDeleteDialogState(false)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
