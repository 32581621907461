import React, { Fragment, useContext } from "react";
import { store } from "../../../../../store";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  Box,
  Divider,
  Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import condeoApi from "../../../../../common/condeoApi";
import notificationAction from "../../../../../store/actions/notificationAction";
import styles from "./PropertyForm.module.scss";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import selectedPropertyAction from "../../../../../store/actions/selectedPropertyAction";
import { useTranslation } from "react-i18next";
import AddressForm from "../../../../../components/Address/AddressForm/AddressForm";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  formContainer: {
    width: "95%",
    margin: "10px 0 0 10px",
    animation: "fadeIn 2s",
  },
}));

export default function PropertyForm({
  onEditModeSwitch,
  propertyId,
  property,
}) {
  const classes = useStyles();
  const history = useHistory();
  const currentPath = history.location.pathname;
  const id = propertyId;
  const { state, dispatch } = useContext(store);
  const { user } = state;
  const { token } = state.auth;
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};
    if (!values.label) {
      errors.label = "This field is Required";
    }
    if (!values.description) {
      errors.description = "This field is Required";
    }
    if (!values.street) {
      errors.street = "This field is Required";
    }
    if (!values.streetNumber) {
      errors.streetNumber = "This field is Required";
    }
    if (!values.place) {
      errors.place = "This field is Required";
    }
    if (!values.poBox) {
      errors.poBox = "This field is Required";
    }
    if (!values.canton) {
      errors.canton = "This field is Required";
    }
    if (!values.postCode) {
      errors.postCode = "This field is Required";
    }
    if (!values.country) {
      errors.country = "This field is Required";
    }
    return errors;
  };
  const { label, description, address } = property ? property : "";
  const formik = useFormik({
    initialValues: {
      label: label ? label : "",
      description: description ? description : "",
      street: address?.street ? address?.street : "",
      streetNumber: address?.streetNumber ? address?.streetNumber : "",
      additionalStreet: address?.additionalStreet
        ? address?.additionalStreet
        : "",
      additionalStreetNumber: address?.additionalStreetNumber
        ? address?.additionalStreetNumber
        : "",
      place: address?.place ? address?.place : "",
      poBox: address?.poBox ? address?.poBox : "",
      postCode: address?.postcode ? address?.postcode : "",
      canton: address?.canton ? address?.canton : "",
      country: address?.country ? address?.country : "",
    },
    validate,
    onSubmit: (values) => {
      updateProperty(values);
    },
  });

  async function updateProperty(values) {
    const model = {
      label: values.label,
      description: values.description,
      organizationId: user?.organization?.id,
      address: {
        id: property?.addressId,
        street: values.street,
        additionalStreet: values.additionalStreet,
        streetNumber: values.streetNumber,
        additionalStreetNumber: values.additionalStreetNumber,
        place: values.place,
        poBox: values.poBox,
        canton: values.canton,
        postCode: values.postCode,
        country: values.country,
      },
    };
    let request;
    let message;
    if (property) {
      request = condeoApi.updateProperty(model, token, property?.id);
      message = "updated";
    } else {
      request = condeoApi.createProperty(model, token);
      message = "added";
    }
    request
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              dispatch(
                selectedPropertyAction({
                  selectedProperty: model,
                })
              );
              dispatch(
                notificationAction({
                  open: true,
                  message: `Property has been ${message} successfully!`,
                })
              );
              if (property) {
                onEditModeSwitch();
              } else {
                history.push("/properties");
              }
            }
          });
        } else if (result.status === 401) {
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.401"),
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  }
  const redirectBack = () => {
    history.goBack();
  };

  return (
    <Box p={2}>
      <Box className={styles.header}>
        <Box display="flex">
          <Typography variant="h6" color="textSecondary">
            {property ? "Edit" : "Add"} Property
          </Typography>
          <Typography variant="caption" color="textSecondary"></Typography>
        </Box>
        {currentPath.split("/")[2] !== "new" && (
          <Tooltip title="Cancel">
            <IconButton onClick={onEditModeSwitch} id="edit-action">
              <Close />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Divider light />
      <Formik enableReinitialize={true}>
        <form onSubmit={formik.handleSubmit} className={classes.grid}>
          <Grid container spacing={3}>
            <Box width="100%" px={2} pt={2} pb={1}>
              <Grid item xs={12} style={{ padding: "1em 0 1em 0" }}>
                <Box className={styles.textFieldMinHeight}>
                  <TextField
                    id="label"
                    variant="outlined"
                    label={t("AllProperties.label")}
                    name="label"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.label}
                    fullWidth
                  />
                  {formik.touched.label && formik.errors.label ? (
                    <Box className={styles.error}>{formik.errors.label}</Box>
                  ) : (
                    ""
                  )}
                </Box>
                <TextField
                  id="description"
                  size="small"
                  variant="outlined"
                  multiline
                  rows={4}
                  label={t("AllProperties.description")}
                  name="description"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                />
                {formik.touched.description && formik.errors.description ? (
                  <Box className={styles.error}>
                    {formik.errors.description}
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
              <AddressForm formik={formik} id={id} />
            </Box>
          </Grid>
          <Box className={styles.gridFooter}>
            <Fragment>
              <Button startIcon={<ArrowBackIcon />} onClick={redirectBack}>
                {t("Actions.back")}
              </Button>
              <Button
                startIcon={<SaveIcon />}
                type="submit"
                color="primary"
                variant="contained"
                className={styles.savePropertyButton}
              >
                {t("Actions.save")} {property && t("Actions.changes")}
              </Button>
            </Fragment>
          </Box>
        </form>
      </Formik>
    </Box>
  );
}
