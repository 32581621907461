import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Divider,
  CardContent,
  Box,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React from "react";
import {
  root,
  tenantCardContent,
  cardRow,
  boldText,
  cardText
} from "./TenantsCard.module.scss";
import { useTranslation } from "react-i18next";

const TenantsCard = ({ tenant, handleTenantDetails }) => {
  const { t } = useTranslation();

  return (
    <Card
      key={tenant?.id}
      className={root}
      onClick={() => handleTenantDetails(tenant)}
    >
      <CardHeader
        avatar={
          <Avatar style={{ backgroundColor: "#303F51" }}>
            {tenant?.firstName[0]}
            {tenant?.lastName[0]}
          </Avatar>
        }
        style={{ padding: "2em" }}
        title={tenant?.firstName}
        subheader={tenant?.email}
        action={
          <IconButton>
            <MoreVert />
          </IconButton>
        }
      ></CardHeader>
      <Divider />
      <CardContent className={tenantCardContent}>
        <Box pb={"23px"}>
          <div className={cardRow}>
            <Typography color="textSecondary" className={cardText}>{t('property')}</Typography>
            <Typography color="textSecondary" className={cardText}>{t('unit')}</Typography>
            <Typography color="textSecondary" className={cardText}>{t('phone')}</Typography>
          </div>
          <div className={cardRow}>
            <Typography className={boldText}>
              {tenant?.unit?.property?.label}
            </Typography>
            <Typography className={boldText}>{tenant?.unit?.label}</Typography>
            <Typography className={boldText}>{tenant?.phone}</Typography>
          </div>
        </Box>
        <div>
          <Divider />
          <Box mt={"28px"}>
            <Typography color="textSecondary">{t('posts')}</Typography>
          </Box>
          <div className={cardRow}>
            <Typography className={boldText}>{t('feed')}</Typography>
            <Typography variant="h5" style={{ color: "#e17055" }}>
              {tenant?.postsCount}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TenantsCard;
