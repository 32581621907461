import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { store } from '../../store'
import { Button, Box } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import {
  rightButton,
  userInfoMenu,
  userInfoHeader,
  userImage,
  email,
  menuIcon,
} from '../User/UserInfo/UserInfo.module.scss'

import { AppBar, IconButton, Toolbar, Typography, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import MenuItem from '@material-ui/core/MenuItem'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import LanguageIcon from '@material-ui/icons/Language'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import logoutAction from '../../store/actions/logoutAction'
const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      backgroundColor: 'white',
      color: '#000',
      borderBottom: '0.5px solid rgba(0, 0, 0, .1)'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    minHeight: '48px',
  }
}))

const HeaderLight = (props) => {
  const globalState = useContext(store)
  const { state } = globalState
  const { dispatch } = globalState
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { user } = state
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const history = useHistory()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)
  }

  const handleSignOut = () => {
    dispatch(logoutAction())
    history.push('/login')
  }

  return (
    <>
      <AppBar position='fixed' className={classes.appBar} elevation={0}>
        <Toolbar variant='regular'>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant='h5'>CONDEO</Typography>
          <>
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
              className={rightButton}
              color='inherit'
              endIcon={
                <ArrowDropDownIcon
                  style={{ fontSize: '30px', marginLeft: '-10px' }}
                />
              }
            >
              {user ? user.name : ''}
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper className={userInfoMenu} style={{ height: '160px', width: '250px' }}>
                    <div className={userInfoHeader}>
                      <div className={user ? userImage : null}></div>
                      <div>
                        <Typography>
                          {user ? user.name : ''} {user ? user.lastName : ''}
                        </Typography>
                        <Typography className={email} color='textSecondary'>
                          {user ? user.email : ''}
                        </Typography>
                      </div>
                    </div>
                    <Divider />
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id='menu-list-grow'
                        onKeyDown={handleListKeyDown}
                      >
                        <Box display='flex' alignItems='center' pl={'1em'}>
                          <LanguageIcon className={menuIcon} />
                            Language:
                          <Button onClick={() => handleLanguageChange('en')}>
                            EN
                          </Button>
                          <Button onClick={() => handleLanguageChange('de')}>
                            DE
                          </Button>
                        </Box>
                        <MenuItem onClick={handleSignOut}>
                          <ExitToAppIcon className={menuIcon} />
                          {t('Profile.signOut')}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default HeaderLight
