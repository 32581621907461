import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { store } from "../../store";
import UserInfo from "../../components/User/UserInfo/UserInfo";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Properties from "../RentalProperties/Properties/Properties";
import AddEditProperty from "../RentalProperties/Properties/AddEditProperty/AddEditProperty";
import PropertyDetails from "../RentalProperties/Properties/PropertyDetails/PropertyDetails";
import Tenants from "../RentalProperties/Tenants/Tenants";
import TenantDetails from "../RentalProperties/Tenants/TenantDetails/TenantDetails";
import AddEditTenant from "../RentalProperties/Tenants/AddEditTenant/AddEditTenant";
import Units from "../RentalProperties/Properties/Units/Units";
import AddEditUnit from "../RentalProperties/Properties/Units/AddEditUnit/AddEditUnit";
import UnitDetails from "../RentalProperties/Properties/Units/UnitDetails/UnitDetails";
import { Menu } from "@material-ui/icons";
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";
import Pinboard from "../Pinboard/Pinboard";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import pinboardAction from "../../store/actions/pinboardAction";
import SocialFeed from "../SocialFeed/SocialFeed";
import Documents from "../Documents/Documents";
import DocumentDetails from "../Documents/DocumentDetails/DocumentDetails";
import Profile from "../Profile/Profile";
import styles from "./Layout.module.scss";
const drawerWidth = 264;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    minHeight: "48px",
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: "48px",
  },
  content: {
    height: "100vh",
    padding: "20px",
    width: "100%",
  },
  mainPannel: {
    padding: "16px",
  },
  listItemText: {
    marginLeft: "22px",
    fontFamily: "Poppins-Light",
  },
}));

const Layout = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { t } = useTranslation();
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { user } = state;
  const { dispatch } = useContext(store);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState(1);

  const sideBarItems = [
    {
      id: 1,
      name: `${t("Dashboard.1")}`,
      icon: (
        <img
          className={styles.image}
          src={require("../../assets/Images/Layout/dashboard.svg")}
          alt="Dashboard"
        />
      ),

      route: "/",
      autoFocus: true,
    },
    {
      id: 2,
      name: `${t("pinboard")}`,
      icon: (
        <img
          className={styles.image}
          src={require("../../assets/Images/Layout/pin.svg")}
          alt="Pinboard"
        />
      ),
      route: "/pinboard/published",
    },
    {
      id: 3,
      name: `${t("feed")}`,
      icon: (
        <img
          className={styles.image}
          src={require("../../assets/Images/Layout/social-feed.svg")}
          alt="Social Feed"
        />
      ),

      route: "/feed",
    },
    {
      id: 4,
      name: `${t("documents")}`,
      icon: (
        <img
          className={styles.image}
          src={require("../../assets/Images/Layout/document.svg")}
          alt="Documents"
        />
      ),

      route: "/documents",
    },
    {
      id: 5,
      name: `${t("Tenants.1")}`,
      icon: (
        <img
          className={styles.image}
          src={require("../../assets/Images/Layout/tenants.svg")}
          alt="Tenants"
        />
      ),

      route: "/tenants",
    },
    {
      id: 6,
      name: `${t("AllProperties.1")}`,
      icon: (
        <img
          className={styles.image}
          src={require("../../assets/Images/Layout/properties.svg")}
          alt="Properties"
        />
      ),

      route: "/properties",
      autoFocus: false,
    },
    {
      id: 7,
      name: `${t("propertyUnits")}`,
      icon: (
        <img
          className={styles.image}
          src={require("../../assets/Images/Layout/units.svg")}
          alt="Units"
        />
      ),

      route: "/units",
    },
  ];
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCollapseClick = (id) => {
    setOpen(!open);
    setActiveLink(id);
  };

  const setSelectedMenuItem = (item) => {
    dispatch(
      pinboardAction({
        selectedItem: item,
      })
    );
  };

  const handleMenuItemClick = (id) => {
    setActiveLink(id);
    setOpen(false);
  };

  const drawer = (
    <div className={styles.container}>
      <List>
        {sideBarItems.map((item, index) => (
          <React.Fragment key={item.id}>
            {item.id !== 2 ? (
              <ListItem
                button
                key={item.id}
                component={Link}
                to={item.route}
                onClick={() => handleMenuItemClick(item.id)}
                className={
                  activeLink === item.id
                    ? styles.activeListItem
                    : styles.listItem
                }
              >
                {item.icon}
                <ListItemText
                  primary={item.name}
                  className={classes.listItemText}
                  key={item.id}
                />
              </ListItem>
            ) : (
              <>
                {" "}
                <ListItem
                  button
                  component={Link}
                  to={item.route}
                  key={item.key}
                  onClick={() => handleCollapseClick(item.id)}
                  className={
                    activeLink === item.id
                      ? styles.activeListItem
                      : styles.listItem
                  }
                >
                  {item.icon}
                  <ListItemText
                    primary={item.name}
                    className={classes.listItemText}
                  />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit key={item.id}>
                  <List
                    component="div"
                    disablePadding
                    key={item.id}
                    className={styles.innerListContainer}
                  >
                    <ListItem
                      button
                      component={Link}
                      to="/pinboard/published"
                      onClick={() => setSelectedMenuItem("published")}
                      className={
                        open ? styles.innerListItemActive : styles.innerListItem
                      }
                    >
                      <img
                        src={require("../../assets/Images/Layout/published-posts.svg")}
                        alt="Published"
                        className={styles.innerImage}
                      />

                      <ListItemText
                        primary={t("posts")}
                        className={classes.listItemText}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/pinboard/drafts"
                      onClick={() => setSelectedMenuItem("drafts")}
                      className={
                        open ? styles.innerListItemActive : styles.innerListItem
                      }
                    >
                      <img
                        src={require("../../assets/Images/Layout/draft-posts.svg")}
                        alt="Drafts"
                        className={styles.innerImage}
                      />
                      <ListItemText
                        primary={t("drafts")}
                        className={classes.listItemText}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/pinboard/myPosts"
                      onClick={() => setSelectedMenuItem("myPosts")}
                      className={
                        open ? styles.innerListItemActive : styles.innerListItem
                      }
                    >
                      <img
                        src={require("../../assets/Images/Layout/my-posts.svg")}
                        alt="My Posts"
                        className={styles.innerImage}
                      />

                      <ListItemText
                        primary={t("myPosts")}
                        className={classes.listItemText}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/pinboard/archived"
                      onClick={() => setSelectedMenuItem("archived")}
                      className={
                        open ? styles.innerListItemActive : styles.innerListItem
                      }
                    >
                      <img
                        src={require("../../assets/Images/Layout/archived-posts.svg")}
                        alt="Archive"
                        className={styles.innerImage}
                      />
                      <ListItemText
                        primary={t("archived")}
                        className={classes.listItemText}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
  const DashboardTranslate = withTranslation()(Dashboard);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant="h5">CONDEO</Typography>
          <UserInfo user={user} />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.mainPannel}>
          <Switch>
            <Route exact path="/" component={DashboardTranslate} />
            <Route exact path="/properties" component={Properties} />
            <Route path="/properties/:id/details" component={PropertyDetails} />
            <Route path="/properties/new" component={AddEditProperty} />
            <Route path="/properties/:id" component={AddEditProperty} />
            <Route exact path="/units" component={Units} />
            <Route path="/units/:id/details" component={UnitDetails} />
            <Route path="/units/new" component={AddEditUnit} />
            <Route path="/units/:id" component={AddEditUnit} />
            <Route exact path="/tenants" component={Tenants} />
            <Route path="/tenants/:id/details" component={TenantDetails} />
            <Route path="/tenants/new" component={AddEditTenant} />
            <Route path="/tenants/:id" component={AddEditTenant} />
            <Route path="/pinboard" component={Pinboard} />
            <Route path="/feed" component={SocialFeed} />
            <Route exact path="/documents" component={Documents} />
            <Route path="/documents/new" component={DocumentDetails} />
            <Route path="/documents/:id/details" component={DocumentDetails} />
            <Route path="/users/:id/profile" component={Profile} />
            <Redirect to="/" />
          </Switch>
        </div>
      </main>
    </div>
  );
};

export default Layout;
