import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import theme from './common/Theme/CondeoTheme'
import LoginPage from './views/LoginPage/LoginPage'
import SignupPage from './views/SignupPage/SignupPage'
import ConfirmAccountPage from './views/ConfirmAccountPage/ConfirmAccountPage'
import ResetPasswordPage from './views/ResetPasswordPage/ResetPasswordPage'
import SetPasswordPage from './views/SetPasswordPage/SetPasswordPage'
import PrivateRoute from './PrivateRoute'
import OrganizationSetup from './views/OrganizationSetup/OrganizationSetup'
import Notification from './components/Notification/Notification'
import OrganizationInvitation from './views/OrganizationSetup/OrganizationInvitation/OrganizationInvitation'
import ConfirmTenantAccount from './views/RentalProperties/Tenants/ConfirmTenantAccount/ConfirmTenantAccount'
import './styles.scss'
import TenantInvitation from './views/RentalProperties/Tenants/TenantInvitation/TenantInvitation'
import Layout from './views/Layout/Layout';
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path='/login' component={LoginPage} />
          <Route path='/signup' component={SignupPage} />
          <Route path='/confirm-account' component={ConfirmAccountPage} />
          <Route path='/confirm-tenant-account' component={ConfirmTenantAccount} />
          <Route path='/reset-password' component={ResetPasswordPage} />
          <Route path='/set-password' component={SetPasswordPage} />
          <PrivateRoute path='/organization-invitation' component={OrganizationInvitation} />
          <PrivateRoute path='/tenant-invitation' component={TenantInvitation} />
          <PrivateRoute path='/setup-organization' component={OrganizationSetup} />
          <PrivateRoute path='/' component={Layout} />
          <Redirect to='/' />
        </Switch>
        <Notification></Notification>
      </BrowserRouter>
    </ThemeProvider>
  )
}
