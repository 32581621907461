import React, { useState, useContext, Fragment } from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Link,
  IconButton,
  Avatar,
  Fab,
} from "@material-ui/core";
import {
  ThumbUpAltRounded,
  MoreVert,
  TextsmsOutlined,
  ShareOutlined,
  ThumbUpAltOutlined,
} from "@material-ui/icons";
import styles from "./Post.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import condeoApi from "../../../common/condeoApi";
import { store } from "../../../store";
import notificationAction from "../../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import { userInitials } from "../../../common/utils";
import Comments from "../../../components/Post/Comments/Comments";
import Likes from "../../../components/Post/Likes/Likes";
const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: "#303F51",
  },
  badge: {
    fontSize: "10px",
  },
}));
export default function Post({ post, index, handlePostDetails }) {
  const { state, dispatch } = useContext(store);
  const { t } = useTranslation();
  const token = state?.auth?.token;
  const user = state?.user;
  const classes = useStyles();

  const [likesDialogState, setLikesDialogState] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [postLikes, setPostLikes] = useState(null);
  const [postComments, setPostComments] = useState(null);
  const [likesCount, setLikesCount] = useState(post?.likesCount);
  const [liked, setLiked] = useState(post?.liked);
  const [commentsCount, setCommentsCount] = useState(post?.commentsCount);

  const fetchPostLikes = (post) => {
    condeoApi
      .fetchFeedPostLikes(token, post)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setPostLikes(response);
              setLikesCount(response?.length);
            }
          });
        } else {
          dispatch(
            notificationAction({
              open: true,
              message: "Failed to fetch Post Likes.",
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchPostComments = (post) => {
    condeoApi
      .fetchFeedPostComments(token, post)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setPostComments(response);
              setCommentsCount(response?.length);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const handleCommentSection = (event, post) => {
    event.preventDefault();
    setExpanded(!expanded);
    fetchPostComments(post);
  };

  const handlePostLikes = (event, post) => {
    event.preventDefault();
    fetchPostLikes(post);
    setLikesDialogState(!!post);
  };

  const handleLikesDialogClose = (event) => {
    event.preventDefault();
    setLikesDialogState(false);
  };

  const handleCommentChange = (event) => {
    event.preventDefault();
    setCommentValue(event.target.value);
  };

  const addPostComment = (event, post) => {
    const model = {
      userId: user?.id,
      feedPostId: post?.id,
      content: commentValue,
    };
    condeoApi
      .createFeedPostComment(token, model)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.general"),
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            setCommentValue("");
            fetchPostComments(post);
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const addPostLike = (event, post) => {
    event.preventDefault();
    const model = {
      userId: user?.id,
      postId: post?.id,
    };
    condeoApi
      .createFeedPostLike(model, token)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.general"),
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            fetchPostLikes(post);
            setLiked(true);
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const deletePostLike = (event, post) => {
    event.preventDefault();
    const model = {
      feedPostId: post?.id,
    };
    condeoApi
      .deleteFeedPostLike(model, token)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.general"),
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            fetchPostLikes(post);
            setLiked(false);
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const deletePostComment = (id, post) => {
    condeoApi
      .deletePostComment(id, token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              fetchPostComments(post);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  return (
    <React.Fragment key={index}>
      <Card className={styles.post} key={post.id}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {post?.isOrganization
                ? userInitials(post?.organizationName)
                : userInitials(
                    post?.createdBy?.name,
                    post?.createdBy?.lastName
                  )}
            </Avatar>
          }
          action={
            <IconButton
              aria-label="settings"
              size="small"
              onClick={(event) => handlePostDetails(event, post)}
            >
              <MoreVert />
            </IconButton>
          }
          title={
            <Link className={styles.pointerCursor}>
              {post?.isOrganization
                ? post?.organizationName
                : `${post?.createdBy?.name} ${post?.createdBy?.lastName}`}
            </Link>
          }
          subheader={post?.createdAt}
        ></CardHeader>
        <Divider />
        <CardContent className={styles.postContentContainer}>
          <Typography>{post?.content}</Typography>
        </CardContent>
        <div className={styles.postReactionsContainer}>
          {likesCount > 0 && (
            <Fragment>
              <Link
                href="#"
                variant="body2"
                className={styles.postReactionItem}
                onClick={(event) => handlePostLikes(event, post)}
              >
                <Fab
                  color="secondary"
                  aria-label="add"
                  size="small"
                  className={styles.likeFab}
                >
                  <ThumbUpAltRounded
                    size="small"
                    className={styles.cardActionsIcon}
                  />
                </Fab>
                <Typography>{likesCount}</Typography>
              </Link>
            </Fragment>
          )}
          {commentsCount > 0 && (
            <Link
              href="#"
              className={styles.postReactionItem}
              onClick={(event) => handleCommentSection(event, post)}
            >
              <Typography>
                {commentsCount} {t("comments")}
              </Typography>
            </Link>
          )}
        </div>
        <Divider />
        <CardActions>
          <Button
            variant="text"
            className={styles.noTextTransformButton}
            startIcon={
              liked ? (
                <ThumbUpAltRounded color="secondary" />
              ) : (
                <ThumbUpAltOutlined color="primary" />
              )
            }
            onClick={(event) =>
              liked ? deletePostLike(event, post) : addPostLike(event, post)
            }
          >
            <Typography className={liked ? styles.activeText : null}>
              {t("like")}
            </Typography>
          </Button>
          <Button
            variant="text"
            onClick={(event) => handleCommentSection(event, post)}
            startIcon={<TextsmsOutlined />}
            className={styles.noTextTransformButton}
          >
            <Typography>{t("comment")}</Typography>
          </Button>
          <Button
            variant="text"
            startIcon={<ShareOutlined />}
            className={styles.noTextTransformButton}
          >
            <Typography>{t("share")}</Typography>
          </Button>
        </CardActions>
        {postComments && (
          <Comments
            expanded={expanded}
            deletePostComment={deletePostComment}
            addPostComment={addPostComment}
            postComments={postComments}
            post={post}
            handleCommentChange={handleCommentChange}
            commentValue={commentValue}
          />
        )}
      </Card>
      <Likes
        postLikes={postLikes}
        dialogState={likesDialogState}
        onClose={handleLikesDialogClose}
      />
    </React.Fragment>
  );
}
