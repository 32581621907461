import { FETCH_CURRENT_USER } from '../actionTypes'

const fetchUserAction = (user) => {
  return {
    type: FETCH_CURRENT_USER,
    payload: user
  }
}

export default fetchUserAction
