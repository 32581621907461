const serverUrl = process.env.REACT_APP_SERVER_URL;

const getDefaultHeaders = (accessToken) => {
  let headers = { "Content-Type": "application/json" };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return headers;
};

const getFileHeaders = (accessToken) => {
  let headers = {
    // 'Content-Type': 'multipart/form-data'
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return headers;
};

const login = (payload) => {
  const resource = `${serverUrl}/api/login`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(null),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const signup = (payload) => {
  const resource = `${serverUrl}/api/signup`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(null),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const addOrganization = (payload, accessToken) => {
  const resource = `${serverUrl}/api/organizations`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(accessToken),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const fetchUser = (accessToken) => {
  const resource = `${serverUrl}/api/user`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(accessToken),
  };
  return fetch(resource, options);
};

const confirmAccount = (payload) => {
  const resource = `${serverUrl}/api/confirm-account`;
  const options = {
    method: "PUT",
    mode: "cors",
    body: JSON.stringify(payload),
    headers: getDefaultHeaders(null),
  };
  return fetch(resource, options);
};

const confirmTenantAccount = (payload) => {
  const resource = `${serverUrl}/api/confirm-tenant-account`;
  const options = {
    method: "PUT",
    mode: "cors",
    body: JSON.stringify(payload),
    headers: getDefaultHeaders(null),
  };
  return fetch(resource, options);
};

const setPassword = (payload) => {
  const resource = `${serverUrl}/api/set-password`;
  const options = {
    method: "PUT",
    mode: "cors",
    body: JSON.stringify(payload),
    headers: getDefaultHeaders(null),
  };
  return fetch(resource, options);
};

const resetPassword = (payload) => {
  const resource = `${serverUrl}/api/reset-password`;
  const options = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
    headers: getDefaultHeaders(null),
  };
  return fetch(resource, options);
};

const inviteUser = (payload, id, token) => {
  const resource = `${serverUrl}/api/organizations/${id}/invite`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};
// properties
const createProperty = (payload, token) => {
  const resource = `${serverUrl}/api/properties`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const fetchProperties = (token) => {
  const resource = `${serverUrl}/api/properties`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchProperty = (token, id) => {
  const resource = `${serverUrl}/api/properties/${id}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const updateProperty = (payload, token, id) => {
  const resource = `${serverUrl}/api/properties/${id}`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const deleteProperty = (token, id) => {
  const resource = `${serverUrl}/api/properties/${id}`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

// tenants
const createTenant = (payload, token) => {
  const resource = `${serverUrl}/api/tenants`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};
const fetchTenants = (token, limit) => {
  const resource = `${serverUrl}/api/tenants?limit=${limit || null}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchTenant = (token, id) => {
  const resource = `${serverUrl}/api/tenants/${id}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};
const fetchTenantsByPropertyId = (token, id) => {
  const resource = `${serverUrl}/api/tenants/property/${id}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const updateTenant = (payload, token, id) => {
  const resource = `${serverUrl}/api/tenants/${id}`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const deleteTenant = (token, id) => {
  const resource = `${serverUrl}/api/tenants/${id}`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};
// units

const createUnit = (payload, token) => {
  const resource = `${serverUrl}/api/units`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};
const fetchUnits = (token) => {
  const resource = `${serverUrl}/api/units`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchUnit = (token, id) => {
  const resource = `${serverUrl}/api/units/${id}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const updateUnit = (payload, token, id) => {
  const resource = `${serverUrl}/api/units/${id}`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const updateUser = (token, payload) => {
  const resource = `${serverUrl}/api/users/${payload.id}`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const deleteUnit = (token, id) => {
  const resource = `${serverUrl}/api/units/${id}`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};
// posts
const createPost = (payload, token) => {
  const resource = `${serverUrl}/api/posts`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};
const fetchPinboardPosts = (token) => {
  const resource = `${serverUrl}/api/posts`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchPost = (token, id) => {
  const resource = `${serverUrl}/api/posts/${id}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const updatePost = (payload, token) => {
  const resource = `${serverUrl}/api/posts/${payload.id}`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};
const archivePost = (payload, token) => {
  const { id } = payload;
  const resource = `${serverUrl}/api/posts/${id}/archive`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};
const draftPost = (payload, token) => {
  const { id } = payload;
  const resource = `${serverUrl}/api/posts/${id}/draft`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const deletePost = (token, id) => {
  const resource = `${serverUrl}/api/posts/${id}`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};
const fetchPostProperties = (token, id) => {
  const resource = `${serverUrl}/api/posts/${id}/properties`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

//social-feed

const createFeedPost = (payload, token) => {
  const resource = `${serverUrl}/api/socialfeed/posts`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};
const fetchFeedPosts = (token) => {
  const resource = `${serverUrl}/api/socialfeed/posts`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};
const fetchFeedPostProperties = (token, id) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${id}/properties`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchFeedPost = (token, id) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${id}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const updateFeedPost = (payload, token) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${payload.id}`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const deleteFeedPost = (token, id) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${id}`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const updatePostState = (token, payload) => {
  const resource = `${serverUrl}/api/posts/${payload.id}/update`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const updateFeedPostState = (token, payload) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${payload.id}/update`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};
const fetchFeedPostLikes = (token, payload) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${payload.id}/likes`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchFeedPostComments = (token, payload) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${payload.id}/comments`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const createFeedPostLike = (payload, token) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${payload.postId}/likes`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const deleteFeedPostLike = (payload, token) => {
  const resource = `${serverUrl}/api/socialfeed/posts/${payload.feedPostId}/likes`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const createFeedPostComment = (token, payload) => {
  const resource = `${serverUrl}/api/socialfeed/posts/comments`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const fetchPinboardPostLikes = (token, payload) => {
  const resource = `${serverUrl}/api/pinboard/posts/${payload.id}/likes`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchPinboardPostComments = (token, payload) => {
  const resource = `${serverUrl}/api/pinboard/posts/${payload.id}/comments`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};
const createPinboardPostLike = (payload, token) => {
  const resource = `${serverUrl}/api/pinboard/posts/${payload.postId}/likes`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const deletePinboardPostLike = (payload, token) => {
  const resource = `${serverUrl}/api/pinboard/posts/${payload.feedPostId}/likes`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const deletePostComment = (id, token) => {
  console.log(id);
  const resource = `${serverUrl}/api/posts/comments/${id}`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const createPinboardPostComment = (token, payload) => {
  const resource = `${serverUrl}/api/pinboard/posts/comments`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const addDocuments = (token, payload) => {
  const { documents } = payload;
  const formData = new FormData();
  for (const index in documents) {
    formData.append("files", documents[index]);
  }
  if (payload.name) {
    formData.append("name", payload.name);
  }
  formData.append("reference", payload.reference);

  const resource = `${serverUrl}/api/documents`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getFileHeaders(token),
    body: formData,
  };
  return fetch(resource, options);
};

const updateDocumentAttachment = (token, payload) => {
  const { documents, documentId, name, reference } = payload;
  const formData = new FormData();
  for (const index in documents) {
    formData.append("files", documents[index]);
  }
  formData.append("name", name);
  formData.append("documentId", documentId);
  formData.append("referenceTarget", reference.target);
  formData.append("referenceList", JSON.stringify(reference.list));

  const resource = `${serverUrl}/api/documents/${documentId}/attachment`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getFileHeaders(token),
    body: formData,
  };
  return fetch(resource, options);
};

const updateDocumentInfo = (token, payload) => {
  const resource = `${serverUrl}/api/documents/${payload.documentId}/info`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

const fetchDocuments = (token) => {
  const resource = `${serverUrl}/api/documents`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const getDocument = (token, payload) => {
  const resource = `${serverUrl}/api/documents/${payload.id}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getFileHeaders(token),
  };
  return fetch(resource, options);
};

const fetchDocument = (token, id) => {
  const resource = `${serverUrl}/api/documents/${id}/data`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const getDocumentReferences = (token, id) => {
  const resource = `${serverUrl}/api/documents/${id}/references`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const deleteUploadedFile = (token, id) => {
  const resource = `${serverUrl}/api/documents/${id}/upload`;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const deleteDocument = (token, id) => {
  const resource = `${serverUrl}/api/documents/${id} `;
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchUserDeviceToken = (token, userId) => {
  const resource = `${serverUrl}/api/users/${userId}/device-token`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
  };
  return fetch(resource, options);
};

const fetchUsersDeviceTokens = (token, properties) => {
  const model = {
    properties: properties,
  };
  const resource = `${serverUrl}/api/users/device-token`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(model),
  };
  return fetch(resource, options);
};

const sendNotification = (token, payload) => {
  const resource = `${serverUrl}/api/notifications`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: getDefaultHeaders(token),
    body: JSON.stringify(payload),
  };
  return fetch(resource, options);
};

module.exports = {
  fetchUserDeviceToken,
  fetchUsersDeviceTokens,
  login,
  signup,
  confirmAccount,
  confirmTenantAccount,
  setPassword,
  resetPassword,
  fetchUser,
  addOrganization,
  inviteUser,
  createProperty,
  fetchProperties,
  fetchProperty,
  updateProperty,
  deleteProperty,
  fetchTenants,
  fetchTenant,
  updateTenant,
  deleteTenant,
  createTenant,
  createUnit,
  updateUnit,
  fetchUnit,
  fetchUnits,
  deleteUnit,
  fetchTenantsByPropertyId,
  createPost,
  updatePost,
  fetchPinboardPosts,
  fetchPost,
  deletePost,
  archivePost,
  draftPost,
  createFeedPost,
  updateFeedPost,
  fetchFeedPosts,
  fetchFeedPost,
  deleteFeedPost,
  fetchPostProperties,
  updatePostState,
  fetchFeedPostProperties,
  fetchFeedPostLikes,
  fetchFeedPostComments,
  createFeedPostComment,
  createFeedPostLike,
  addDocuments,
  fetchDocuments,
  deleteDocument,
  updateDocumentAttachment,
  updateDocumentInfo,
  deleteUploadedFile,
  fetchDocument,
  getDocumentReferences,
  getDocument,
  updateFeedPostState,
  deleteFeedPostLike,
  createPinboardPostComment,
  createPinboardPostLike,
  deletePinboardPostLike,
  fetchPinboardPostComments,
  fetchPinboardPostLikes,
  sendNotification,
  updateUser,
  deletePostComment,
};
