import React, { useContext } from "react";
import { Formik } from "formik";
import { useFormik } from "formik";
import { Box, TextField, Button } from "@material-ui/core";
import { store } from "../../../store";
import styles from "./ProfileForm.module.scss";
import { Done } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const ProfileForm = ({ editUser }) => {
  const { state } = useContext(store);
  const { user } = state;
  const { t } = useTranslation()

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "This field is Required";
    }
    if (!values.lastName) {
      errors.lastName = "This field is Required";
    }
    if (!values.organizationName) {
      errors.organizationName = "This field is Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: user?.name ?? "",
      lastName: user?.lastName ?? "",
      organizationName: user?.organization?.name ?? "",
    },
    validate,
    onSubmit: (values) => {
      editUser(values);
    },
  });

  return (
    <Formik enableReinitialize={true}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={styles.textFieldMinHeight}>
          <TextField
            id="firstName"
            variant={"outlined"}
            label={t('Tenants.firstName')}
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            fullWidth
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className={styles.error} style={{ fontFamily: "Poppins" }}>
              {formik.errors.firstName}
            </div>
          ) : (
            ""
          )}
        </Box>
        <Box className={styles.textFieldMinHeight}>
          <TextField
            id="lastName"
            variant="outlined"
            label={t('Tenants.lastName')}
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            fullWidth
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className={styles.error} style={{ fontFamily: "Poppins" }}>
              {formik.errors.lastName}
            </div>
          ) : (
            ""
          )}
        </Box>
        <Box className={styles.textFieldMinHeight}>
          <TextField
            id="organizationName"
            variant="outlined"
            label={t('Organization.name')}
            name="organizationName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.organizationName}
            fullWidth
          />
          {formik.touched.organizationName && formik.errors.organizationName ? (
            <div className={styles.error} style={{ fontFamily: "Poppins" }}>
              {formik.errors.organizationName}
            </div>
          ) : (
            ""
          )}
        </Box>
        <Box m={4} mr={0} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            endIcon={<Done />}
            onClick={() => editUser()}
          >
           {t('Actions.save')}
          </Button>
        </Box>
      </form>
    </Formik>
  );
};

export default ProfileForm;
