/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, Fragment } from "react";
import { Typography, Divider, Tabs, Paper, Tab, Box } from "@material-ui/core";
import Tenants from "../../Tenants/Tenants";
import Grid from "@material-ui/core/Grid";
import styles from "./PropertyDetails.module.scss";
import { store } from "../../../../store";
import condeoApi from "../../../../common/condeoApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import notificationAction from "../../../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import Units from "../Units/Units";
import PropertyData from "./PropertyData/PropertyData";
import PropertyForm from "./PropertyForm/PropertyForm";
export default function PropertyDetails(props) {
  const { dispatch, state } = useContext(store);
  const { token } = state.auth;
  const { t } = useTranslation();
  const { id } = props.match.params;
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState("units");
  const [property, setproperty] = useState(null);
  const [editingMode, setEditingMode] = useState(false);

  useEffect(() => {
    fetchProperty(token, id);
  }, [editingMode]);

  const fetchProperty = () => {
    condeoApi
      .fetchProperty(token, id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setproperty(response);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <div className={styles.header}>
        <Typography variant="h6">Property Details</Typography>
      </div>
      <Divider />
      <Grid container direction="row" spacing={3} className={styles.container}>
        <Grid item xs={6} className={styles.propertyFormContainer}>
          {!property && <CircularProgress color="primary" />}
          {property && (
            <Paper>
              {editingMode ? (
                <PropertyForm
                  propertyId={id}
                  property={property}
                  onEditModeSwitch={() => setEditingMode(!editingMode)}
                />
              ) : (
                <PropertyData
                  property={property}
                  onEditModeSwitch={() => setEditingMode(!editingMode)}
                />
              )}
            </Paper>
          )}
        </Grid>
        <Grid item xs={6} className={styles.tenantsFormContainer}>
          <Paper square>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              style={{ marginTop: "10px" }}
            >
              <Tab label="Property Units" onClick={() => setPage("units")} />
              <Tab label="Tenants" onClick={() => setPage("tenants")} />
            </Tabs>
          </Paper>
          <Paper>
            <Box p={1}>
              {page === "tenants" && property ? (
                <Tenants propertyId={id} />
              ) : (
                property && <Units property={property} propertyId={id} />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}
