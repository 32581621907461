/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Button,
  Divider,
  TextField,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControlLabel,
  DialogActions,
} from "@material-ui/core";
import {
  ImageOutlined,
  AttachFileOutlined,
  LocationOnOutlined,
  Check,
  BookmarkBorder,
} from "@material-ui/icons";
import styles from "./PostCreateDialog.module.scss";
import condeoApi from "../../common/condeoApi";
import { store } from "../../store";
import notificationAction from "../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import MultiSelect from "../../common/Autocomplete/MultiSelect";

function PostCreateDialog({
  open,
  onDialogClose,
  editingPost,
  onCreate,
  onUpdate,
  postState,
}) {
  useEffect(() => {
    if (postState === "editing") {
      setContent(editingPost?.content);
    } else {
      setContent("");
      setSelectedProperties([]);
    }
    fetchProperties();
  }, [open]);

  useEffect(() => {
    if (open === true && editingPost) {
      fetchPostProperties(editingPost?.id);
    }
  }, [open]);

  useEffect(() => {
    setPostAsOrganization(editingPost?.isOrganization);
  }, [editingPost]);

  const [properties, setProperties] = useState(null);
  const { state, dispatch } = useContext(store);
  const [content, setContent] = useState(editingPost?.content || "");
  const [postAsOrganization, setPostAsOrganization] = useState(
    editingPost?.isOrganization
  );
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const token = state?.auth?.token;
  const user = state?.user;

  const { t } = useTranslation();

  const handleTextChange = (event) => {
    event.preventDefault();
    setContent(event.target.value);
  };

  const fetchProperties = () => {
    condeoApi
      .fetchProperties(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setProperties(response.properties);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const fetchPostProperties = () => {
    condeoApi
      .fetchFeedPostProperties(token, editingPost?.id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setSelectedProperties(response);
            }
          });
        } else {
          console.log("Something went wrong...");
        }
      })
      .catch((err) => {
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const onPostCreate = (event, editingPost) => {
    if (editingPost) {
      onUpdate(editingPost, selectedProperties, content, postAsOrganization);
    } else {
      onCreate(
        event,
        "published",
        selectedProperties,
        content,
        postAsOrganization
      );
    }
  };

  const handleSaveAsDraft = (event, state) => {
    setSaveAsDraft(true);
    onCreate(event, "draft", selectedProperties, content, postAsOrganization);
  };

  return (
    <Dialog open={open} maxWidth="xl" onClose={(event) => onDialogClose(event)}>
      <DialogTitle disableTypography={true}>
        <Typography variant="subtitle2">{t("createPost")}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={styles.dialogContent}>
        <TextField
          id="standard-multiline-flexible"
          label={`${t("whatIsOnYourMind")}, ${user?.name}?`}
          multiline
          fullWidth
          rowsMax={4}
          rows={3}
          value={content}
          onChange={handleTextChange}
          autoFocus
        />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <IconButton aria-label="add to favorites">
          <ImageOutlined />
        </IconButton>
        <IconButton aria-label="share">
          <AttachFileOutlined />
        </IconButton>
        <IconButton aria-label="share">
          <LocationOnOutlined />
        </IconButton>
        <Divider />
      </DialogActions>
      <Divider />
      <div className={styles.formControlContainer}>
        <FormControlLabel
          control={
            <Switch
              checked={postAsOrganization}
              name="checkedA"
              onChange={(event) => setPostAsOrganization(event.target.checked)}
            />
          }
          label={`${t("postAs")} "${user?.organization?.name}"`}
        />
        {properties && (
          <MultiSelect
            items={properties}
            selectedItems={selectedProperties}
            searchByProperty="id"
            label={t("AllProperties.select")}
            style={{ minWidth: "30em", maxWidth: "45em", marginTop: "1em" }}
            onChange={setSelectedProperties}
          />
        )}
      </div>
      <div className={styles.postButtonContainer}>
        <Button
          style={{ marginRight: "5px" }}
          disabled={!content}
          variant="outlined"
          startIcon={saveAsDraft ? <Check /> : <BookmarkBorder />}
          onClick={(event) => handleSaveAsDraft(event, "draft")}
        >
          {t("Actions.saveAsDraft")}
        </Button>
        <Button
          variant="contained"
          disabled={!content}
          color="primary"
          style={{ width: "150px" }}
          onClick={(event) => onPostCreate(event, editingPost)}
        >
          {editingPost ? t("Actions.update") : t("Actions.post")}
        </Button>
      </div>
    </Dialog>
  );
}

export default PostCreateDialog;
