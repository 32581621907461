import React from 'react'
import { container } from './OrganizationInvitation.module.scss'
import ConfirmAccountPage from '../../ConfirmAccountPage/ConfirmAccountPage'
import { useQueryParams } from 'hookrouter'

export default function OrganizationInvitation() {
  const queryParams = useQueryParams()

  const model = {
    email: queryParams[0].email,
    invitationToken: queryParams[0].invitationToken,
    organizationId: queryParams[0].organizationId,
    confirmationToken: queryParams[0].confirmationToken,
  }
  const { email, invitationToken, organizationId, confirmationToken } = model
  return (
    <div className={container}>
      <ConfirmAccountPage
        email={email}
        invitationToken={invitationToken}
        organizationId={organizationId}
        confirmationToken={confirmationToken}
      />
    </div>
  )
}
