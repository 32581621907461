/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import condeoApi from "../../../../common/condeoApi";
import notificationAction from "../../../../store/actions/notificationAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory, useLocation } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, ListItemIcon, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArchiveIcon from "@material-ui/icons/Archive";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteDialog from "../../../../common/Dialogs/DeleteDialog/DeleteDialog";
import styles from "./Units.module.scss";
import AddIcon from "@material-ui/icons/Add";
import {
  IconButton,
  Button,
  Table,
  TableHead,
  TablePagination,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import { store } from "../../../../store";
import MenuIcon from "@material-ui/icons/Menu";
import { useTranslation } from "react-i18next";
import unitsImg from "../../../../assets/Images/unit.png";
const useStyles = makeStyles((theme) => ({
  root: {},
  grid: {
    flexGrow: 1,
  },
  container: {
    maxHeight: 500,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  textFieldMargin: {
    marginTop: "20px",
  },
  icon: {
    minWidth: "35px",
  },
  spinner: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    justifyContent: "center",
  },
  actionsColumn: {
    width: "140px",
    paddingLeft: "30px",
  },
}));

export default function Units(props) {
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [units, setUnits] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogState, setDialogState] = React.useState(false);
  const [deletingUnit, setDeletingUnit] = React.useState(false);
  const { dispatch, state } = useContext(store);
  const { user } = state;
  const { token } = state.auth;
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    fetchUnits();
  }, []);

  const fetchUnits = () => {
    condeoApi
      .fetchUnits(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              props?.property
                ? setUnits(
                    response.units
                      .filter(
                        (unit) => unit.organizationId === user?.organization?.id
                      )
                      .filter(
                        (unit) =>
                          Number(unit.propertyId) === Number(props.property.id)
                      )
                  )
                : setUnits(
                    response.units.filter(
                      (unit) => unit.organizationId === user?.organization?.id
                    )
                  );
            }
          });
        } else {
          console.log("Something went wrong fetching units.");
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDialogState(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleUnitCreate = () => {
    if (location.pathname.split("/")[1] === "properties") {
      localStorage.setItem("propertyId", props.propertyId);
    } else {
      localStorage.setItem("propertyId", undefined);
    }
    history.push("/units/new");
  };
  const onEditUnit = (unit) => {
    localStorage.setItem("editingData", JSON.stringify(unit));
    localStorage.setItem("propertyId", undefined);
    history.push(`/units/${unit.id}/details`);
  };
  const handleDeleteMenu = (event, unit) => {
    setAnchorEl(event.currentTarget);
    setDeletingUnit(unit);
  };
  const handleDeleteDialog = () => {
    setDialogState(true);
    setAnchorEl(null);
  };
  const handlePermanentDelete = () => {
    condeoApi
      .deleteUnit(token, deletingUnit.id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              setDialogState(false);
              fetchUnits();
              notificationAction({
                open: true,
                message: "Unit has been deleted successfully",
              });
            }
          });
        } else if (result.status === 401) {
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.401"),
            })
          );
        } else if (result.status === 409) {
          if (result.statusText === "Conflict") {
            setDialogState(false);
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.constraint"),
              })
            );
          }
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  return (
    <React.Fragment>
      <div className={styles.header}>
        <Typography variant="h6">{t("propertyUnits")}</Typography>
        {user?.admin && units && units?.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleUnitCreate}
            startIcon={<AddIcon />}
          >
            {t("Units.newUnit")}
          </Button>
        )}
      </div>
      {units && units.length > 0 && (
        <Paper className={classes.root}>
          {!units && <LinearProgress />}
          <DeleteDialog
            open={dialogState}
            onDialogClose={handleClose}
            onDelete={handlePermanentDelete}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("AllProperties.label")}</TableCell>
                  <TableCell>{t("AllProperties.description")}</TableCell>
                  <TableCell>{t("Tenants.remarks")}</TableCell>
                  <TableCell>{t("Units.rooms")}</TableCell>
                  <TableCell>Property</TableCell>
                  <TableCell align="center">{t("Actions.1")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {units?.map((unit) => {
                  return (
                    <TableRow key={unit.id}>
                      <TableCell className={styles.tableCell}>
                        {unit?.label}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {unit?.description}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {unit?.remarks}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {unit?.rooms}
                      </TableCell>
                      <TableCell className={styles.tableCell}>
                        {unit?.property?.label}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => onEditUnit(unit)}
                          id="unit-details"
                        >
                          <MenuIcon />
                        </IconButton>
                        <IconButton
                          onClick={(event) => handleDeleteMenu(event, unit)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  elevation={1}
                >
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon className={classes.icon}>
                      <ArchiveIcon fontSize="small" />
                    </ListItemIcon>
                    {t("Delete.archive")}
                  </MenuItem>
                  <MenuItem onClick={() => handleDeleteDialog()}>
                    <ListItemIcon className={classes.icon}>
                      <DeleteOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    {t("Delete.permanentDelete")}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon className={classes.icon}>
                      <CloseIcon fontSize="small" />
                    </ListItemIcon>
                    {t("Actions.cancel")}
                  </MenuItem>
                </Menu>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={units ? units.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {units && units.length === 0 && (
        <Box className={styles.emptyStateContainer}>
          <img src={unitsImg} alt="No Units" className={styles.emptyStateImg} />
          <Typography
            variant="body2"
            color="textSecondary"
            className={styles.emptyStateText}
          >
            There are currently no Units to display.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUnitCreate}
            endIcon={<AddIcon />}
            className={styles.emptyStateBtn}
          >
            {t("Units.newUnit")}
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
}
