import React, { useContext } from 'react'
import {
  Card,
  Typography,
  TextField,
  Grid,
  Link,
  Button,
  Container,
  CardContent
} from '@material-ui/core'
import { card, container, submitButton, gridMargin } from '../auth.module.scss'
import { store } from '../../store'
import notificationAction from '../../store/actions/notificationAction'
import { useForm } from 'react-hook-form'
import condeoApi from '../../common/condeoApi'
export default function SignupPage() {
  const globalState = useContext(store)
  const { dispatch } = globalState

  const { register, handleSubmit, errors } = useForm()


  const hasError = (inputName) => !!(errors && errors[inputName])
  const onSubmit = (data) => {
    const model = {
      email: data.email,
    }
    condeoApi
      .signup(model)
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                error: true,
                message: response.message,
              })
            )
          } else {
            dispatch(
              notificationAction({
                open: true,
                error: false,
                message:
                  'An email was sent for you to confirm the sign up process.',
              })
            )
          }
        })
      })
      .catch((err) => {
        console.log('Could not communicate with server! ', err.message)
        dispatch(
          notificationAction({
            open: true,
            message: 'Something went wrong',
          })
        )
      })
  }
  return (
    <Container className={container}>
      <Card className={card}>
        <CardContent>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                key='email'
                id='email'
                label='Email Address'
                name='email'
                size='small'
                inputRef={register({
                  required: 'Email Address is required!',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address',
                  },
                })}
                error={hasError('email')}
                helperText={
                  hasError('email') ? `${errors.email.message}` : null
                }
              />
              <div className={submitButton}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                >
                  Sign Up
                </Button>
              </div>
              <Grid container className={gridMargin}>
                <Grid item>
                  <Link href='/login' variant='body2'>
                    {'Already have an account? Login here.'}
                  </Link>
                </Grid>
              </Grid>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}
