import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import detector from "i18next-browser-languagedetector";
import en from './components/i18n/en.json'
import de from './components/i18n/de.json'


const languages = ['en', 'de']

const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  }
};


i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: languages,
    fallbackLng: 'en',
    debug: true,
    resources,
    interpolation: {
      escapeValue: false,
    },
  })
  console.log('language: ', i18n.language)
  console.log('language: ', i18n)
export default i18n
