/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  List,
  Box,
  ListItem,
  Divider,
  Button,
} from "@material-ui/core";
import { store } from "../../../../store";
import condeoApi from "../../../../common/condeoApi";
import styles from "./TenantDetails.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import notificationAction from "../../../../store/actions/notificationAction";
import { useHistory } from "react-router-dom";
import { ArrowRightAlt } from "@material-ui/icons";
import TenantForm from "./TenantForm/TenantForm";
import TenantData from "./TenantData/TenantData";

const useStyles = makeStyles((theme) => ({
  tenantDetailsContainer: {
    marginTop: "10px",
  },
  propertyInfoContainer: {
    maxWidth: "40%",
  },
  tenantFormContainer: {
    paddingLeft: "0",
  },
  minWidth: "54%",
}));

export default function TenantDetails(props) {
  const { dispatch, state } = useContext(store);
  const { token } = state.auth;
  const propertyId = JSON.parse(localStorage.getItem("propertyId"));
  const history = useHistory();
  const classes = useStyles();
  const { id } = props.match.params;
  const [tenant, setTenant] = useState(null);
  const [property, setProperty] = useState(null);
  const [editingMode, setEditingMode] = useState(false);

  useEffect(() => {
    fetchTenant();
    fetchProperty();
  }, [editingMode]);

  const fetchTenant = () => {
    condeoApi
      .fetchTenant(token, id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setTenant(response);
            }
          });
        } else if (result.status === 401) {
          dispatch(
            notificationAction({
              open: true,
              message: "Unauthorized!",
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: "Something went wrong!",
          })
        );
      });
  };
  const fetchProperty = () => {
    condeoApi
      .fetchProperty(token, propertyId)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setProperty(response);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: "Something went wrong!",
          })
        );
      });
  };

  return (
    <Fragment>
      <Box className={styles.header}>
        <Typography variant="h6">Tenant Details</Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="row"
        spacing={3}
        className={classes.tenantDetailsContainer}
      >
        <Grid item xs={6} className={classes.propertyInfoContainer}>
          <Paper className={styles.propertyInfoContainer}>
            {!property && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <CircularProgress color="primary" />
              </Box>
            )}
            {property && (
              <div className={styles.overFlow}>
                <Box ml={2}>
                  <Box
                    display="flex"
                    mb={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h5">
                      {tenant?.unit?.property?.label}
                    </Typography>
                    {property && (
                      <Button
                        color="secondary"
                        endIcon={<ArrowRightAlt />}
                        onClick={() =>
                          history.push(`/properties/${property?.id}/details`)
                        }
                        style={{ textTransform: "none" }}
                      >
                        Details
                      </Button>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    mb={1}
                    className={styles.propertyDescription}
                  >
                    <Typography variant="caption" color="textSecondary">
                      Description
                    </Typography>
                    <Typography variant="body2">
                      {tenant?.unit?.property?.description}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={styles.addressInfo}>
                  <List>
                    <ListItem>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption" color="textSecondary">
                          Street
                        </Typography>
                        <Typography variant="subtitle2">
                          {property?.address?.street}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption" color="textSecondary">
                          Street Number
                        </Typography>
                        <Typography variant="subtitle2">
                          {property?.address?.streetNumber}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption" color="textSecondary">
                          Place
                        </Typography>
                        <Typography variant="subtitle2">
                          {property?.address?.place}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption" color="textSecondary">
                          Country
                        </Typography>
                        <Typography variant="subtitle2">
                          {property?.address?.country}
                        </Typography>
                      </Box>
                    </ListItem>
                  </List>
                </Box>
              </div>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6} className={classes.tenantFormContainer}>
          <Paper>
            {editingMode ? (
              <TenantForm
                id={id}
                tenant={tenant}
                onEditModeSwitch={() => setEditingMode(!editingMode)}
              />
            ) : (
              <TenantData
                tenant={tenant}
                onEditModeSwitch={() => setEditingMode(!editingMode)}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}
