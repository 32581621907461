import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  TextField,
  CircularProgress,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu,
  ListItemIcon,
  MenuItem,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import styles from "./Pinboard.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { BookmarkBorder, Check } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {
  ImageOutlined,
  AttachFileOutlined,
  LocationOnOutlined,
  Edit,
  ArchiveOutlined,
} from "@material-ui/icons";
import condeoApi from "../../common/condeoApi";
import { store } from "../../store";
import notificationAction from "../../store/actions/notificationAction";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useFormik } from "formik";
import { Formik } from "formik";
import DeleteDialog from "../../common/Dialogs/DeleteDialog/DeleteDialog";
import Post from "./Post/Post";
import MultiSelect from "../../common/Autocomplete/MultiSelect";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: "35px",
  },
}));

export default function Posts(props) {
  useEffect(() => {
    fetchPosts();
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(store);
  const token = state?.auth?.token;
  const user = state?.user;

  const [anchorEl, setAnchorEl] = useState(null);
  const [posts, setPosts] = useState(null);
  const [properties, setProperties] = useState(null);
  const [selectedProperties, setSelectedProperties] = useState(null);
  const [dialogState, setDialogState] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [postAsOrganization, setPostAsOrganization] = useState(
    editingPost?.organizationId ? true : false
  );
  const [title] = useState("");
  const [content, setContent] = useState("");
  const [deleteDialogState, setDeleteDialogState] = useState(false);
  const [deletingPost, setDeletingPost] = useState(null);

  const handlePostCreate = (event, state) => {
    event.preventDefault();
    const model = {
      content: content,
      createdBy: user?.id,
      state: state,
      properties: selectedProperties,
      isOrganization: postAsOrganization,
      organizationId: user?.organization.id,
    };
    if (
      state !== "draft" &&
      (!selectedProperties || selectedProperties?.length === 0)
    ) {
      return dispatch(
        notificationAction({
          open: true,
          message: "Please select a property",
        })
      );
    }
    condeoApi.createPost(model, token).then((result) => {
      result
        .json()
        .then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.general"),
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            dispatch(
              notificationAction({
                open: true,
                message: `${
                  state === "draft" ? "Draft" : "Post"
                } has been added successfully`,
              })
            );
            setDialogState(false);
            fetchPosts();
            setContent("");
            setPostAsOrganization(false);
          }
        })
        .catch((err) => {
          console.log("Could not communicate with the server!", err.message);
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        });
    });
  };

  const fetchProperties = () => {
    condeoApi
      .fetchProperties(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setProperties(
                response.properties.filter(
                  (property) =>
                    property.organizationId === user.organization?.id
                )
              );
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const fetchPostProperties = (id) => {
    condeoApi
      .fetchPostProperties(token, id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setSelectedProperties(response);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const fetchPosts = () => {
    condeoApi
      .fetchPinboardPosts(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setPosts(response.posts);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const deletePost = () => {
    condeoApi
      .deletePost(token, deletingPost?.id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              setAnchorEl(null);
              dispatch(
                notificationAction({
                  open: true,
                  message: "Post has been deleted successfully",
                })
              );
              fetchPosts();
              setDeleteDialogState(false);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const handleTextChange = (event) => {
    event.preventDefault();
    setContent(event.target.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePostDelete = (post) => {
    setDeletingPost(post);
    setDeleteDialogState(!!post);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.label) {
      errors.label = "This field is Required";
    }
    if (!values.description) {
      errors.description = "This field is Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {},
    validate,
    onSubmit: (values) => {
      handlePostCreate(values);
    },
  });

  const filteredPosts = (posts) => {
    const state = props.location.pathname.split("/")[2];
    if (state === "drafts") {
      if (!user?.admin) {
        return [];
      } else {
        return posts?.filter((post) => post.state === "draft");
      }
    } else if (state === "archived") {
      return posts?.filter((post) => post.state === "archived");
    } else if (state === "myPosts") {
      return posts
        ?.filter((post) => post.createdBy?.id === user?.id)
        .filter((p) => p.state === "published")
        .filter((p) => !p.isOrganization);
    } else {
      return posts?.filter((post) => post.state === "published");
    }
  };
  const handleDialogClose = (event) => {
    event.preventDefault();
    setDialogState(false);
  };
  const handleCreateDialog = () => {
    setDialogState(true);
    setSaveAsDraft(false);
    setEditingPost(null);
    setContent("");
    setPostAsOrganization(false);
    setSelectedProperties(null);
  };
  const handleEditDialogState = (event, post, state) => {
    event.preventDefault();
    setAnchorEl(null);
    setDialogState(true);
    setContent(post?.content);
    setPostAsOrganization(post?.isOrganization);
  };

  const handlePostUpdate = (post, state) => {
    const model = {
      id: post?.id,
      title: title,
      content: content,
      isOrganization: postAsOrganization,
      properties: selectedProperties,
      state: state === "draft" ? state : post?.state,
    };
    if (
      state !== "draft" &&
      (!selectedProperties || selectedProperties?.length === 0)
    ) {
      return dispatch(
        notificationAction({
          open: true,
          message: "Please select a property",
        })
      );
    }
    condeoApi
      .updatePost(model, token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              dispatch(
                notificationAction({
                  open: true,
                  message: `${
                    state === "draft" ? "Draft" : "Post"
                  } has been updated successfully`,
                })
              );
              fetchPosts();
              setAnchorEl(null);
              setDialogState(!response.success);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const updatePostState = (post, state, currentRoute) => {
    const model = {
      id: post.id,
      state: state,
    };
    condeoApi
      .updatePostState(token, model)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              if (state.endsWith("e")) {
                state = `${state}d`;
              } else state = `${state}ed`;

              dispatch(
                notificationAction({
                  open: true,
                  message: `${
                    currentRoute === "drafts" ? "Draft" : "Post"
                  } has been ${state} successfully`,
                })
              );
              fetchPosts();
              setAnchorEl(null);
              setDialogState(!response.success);
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const handleActionsMenu = (post, event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setEditingPost(post);
    fetchPostProperties(post?.id);
  };

  const { selectedItem } = state?.pinboard;
  const currentRoute = props.location.pathname.split("/")[2];
  const getNameByRoute = (route) => {
    switch (route) {
      case "published":
        return "Published";
      case "drafts":
        return "Drafts";
      case "myPosts":
        return "My posts";
      case "archived":
        return "Archived";
      default:
        return "";
    }
  };
  return (
    <Fragment>
      <Box className={styles.header}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" style={{ marginRight: "2px" }}>
            {t("pinboard")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            ({getNameByRoute(currentRoute)})
          </Typography>
        </Box>
        <Typography variant="caption" color="textSecondary">
          Here you can post information for Tenants and Admins of your
          organization.
        </Typography>
      </Box>
      <div className={styles.container}>
        <div className={styles.postsContainer}>
          <div>
            {(user?.admin &&
              (selectedItem === "myPosts" || selectedItem === "archived")) ||
            !user?.admin ? (
              ""
            ) : (
              <Formik enableReinitialize={true}>
                <form onSubmit={formik.handleSubmit}>
                  <Card className={styles.postHeader}>
                    <CardHeader
                      subheader={
                        currentRoute === "drafts"
                          ? t("createDraft")
                          : t("createPost")
                      }
                    ></CardHeader>
                    <Divider />
                    <CardContent className={styles.postCardContent}>
                      <div
                        className={styles.postCreateCard}
                        onClick={() => handleCreateDialog()}
                      >
                        <Typography color="textSecondary">
                          {t("whatIsOnYourMind")}, {user?.name}?
                        </Typography>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardActions style={{ padding: "0 8px", display: "flex" }}>
                      <IconButton aria-label="add to favorites">
                        <ImageOutlined />
                      </IconButton>
                      <IconButton aria-label="share">
                        <AttachFileOutlined />
                      </IconButton>
                      <IconButton aria-label="share">
                        <LocationOnOutlined />
                      </IconButton>
                    </CardActions>
                  </Card>
                </form>
              </Formik>
            )}
          </div>
          <div className={styles.postsContainer}>
            {!posts && <CircularProgress />}
            {filteredPosts(posts, state.pinboard.selectedItem)?.length ===
              0 && (
              <Box className={styles.emptyStateContainer}>
                <img
                  alt="No posts"
                  src={require("../../assets/Images/posts.png")}
                  className={styles.emptyStateImg}
                />
                <Typography variant="body2" color="textSecondary">
                  There are no Pinboard posts to display. Create your first post
                  now!
                </Typography>
              </Box>
            )}
            {posts &&
              filteredPosts(posts, state.pinboard.selectedItem)?.map(
                (post, index) => {
                  return (
                    <Post
                      key={post?.id}
                      post={post}
                      index={index}
                      handlePostDetails={(event, post) =>
                        handleActionsMenu(post, event)
                      }
                    />
                  );
                }
              )}
            <Menu
              id="post-actions-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              elevation={1}
            >
              <MenuItem
                onClick={(event) =>
                  handleEditDialogState(event, editingPost, "edit")
                }
                key={editingPost?.id}
              >
                <ListItemIcon className={classes.icon}>
                  <Edit fontSize="small" />
                </ListItemIcon>
                {t("edit")}
              </MenuItem>
              {currentRoute === "drafts" || currentRoute === "archived" ? (
                <MenuItem
                  onClick={() =>
                    updatePostState(editingPost, "publish", currentRoute)
                  }
                >
                  <ListItemIcon className={classes.icon}>
                    <ArchiveOutlined fontSize="small" />
                  </ListItemIcon>
                  {t("publish")}
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() =>
                    updatePostState(editingPost, "archive", currentRoute)
                  }
                >
                  <ListItemIcon className={classes.icon}>
                    <ArchiveOutlined fontSize="small" />
                  </ListItemIcon>
                  {t("Delete.archive")}
                </MenuItem>
              )}
              <MenuItem onClick={() => handlePostDelete(editingPost)}>
                <ListItemIcon className={classes.icon}>
                  <DeleteOutlineIcon fontSize="small" />
                </ListItemIcon>
                {t("delete")}
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon className={classes.icon}>
                  <CloseIcon fontSize="small" />
                </ListItemIcon>
                {t("Actions.cancel")}
              </MenuItem>
            </Menu>
            <Dialog
              open={dialogState}
              maxWidth="xl"
              onClose={(event) => handleDialogClose(event)}
            >
              <DialogTitle disableTypography={true}>
                <Typography variant="subtitle2">
                  {currentRoute !== "drafts"
                    ? t("createPost")
                    : t("createDraft")}
                </Typography>
              </DialogTitle>
              <Divider />
              <DialogContent className={styles.dialogContent}>
                <TextField
                  id="pinboard-post-content"
                  label={`${t("whatIsOnYourMind")}, ${user?.name}`}
                  multiline
                  fullWidth
                  rowsMax={4}
                  rows={3}
                  style={{ flex: 3 }}
                  value={content}
                  onChange={handleTextChange}
                  autoFocus
                />
              </DialogContent>
              <DialogActions className={styles.dialogActions}>
                <IconButton aria-label="add to favorites">
                  <ImageOutlined />
                </IconButton>
                <IconButton aria-label="share">
                  <AttachFileOutlined />
                </IconButton>
                <IconButton aria-label="share">
                  <LocationOnOutlined />
                </IconButton>
                <Divider />
              </DialogActions>
              <Divider />
              <div className={styles.formControlContainer}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={postAsOrganization ? true : false}
                      name="checkedA"
                      onChange={(event) =>
                        setPostAsOrganization(event.target.checked)
                      }
                    />
                  }
                  label={t("Organization.post")}
                />
                {properties && (
                  <MultiSelect
                    items={properties}
                    selectedItems={selectedProperties}
                    label={t("AllProperties.select")}
                    searchByProperty="id"
                    style={{
                      marginLeft: "2em",
                      minWidth: "30em",
                      maxWidth: "45em",
                      marginTop: "1em",
                    }}
                    onChange={setSelectedProperties}
                  />
                )}
              </div>
              <div className={styles.postButtonContainer}>
                <Button
                  style={{ marginRight: "5px" }}
                  disabled={!content}
                  variant="outlined"
                  startIcon={saveAsDraft ? <Check /> : <BookmarkBorder />}
                  onClick={(event) =>
                    editingPost
                      ? handlePostUpdate(editingPost, "draft")
                      : handlePostCreate(event, "draft")
                  }
                >
                  {t("Actions.saveAsDraft")}
                </Button>
                {currentRoute !== "drafts" && (
                  <Button
                    variant="contained"
                    disabled={!content}
                    color="primary"
                    style={{ width: "150px" }}
                    onClick={(event) =>
                      editingPost
                        ? handlePostUpdate(editingPost, "published")
                        : handlePostCreate(event, "published")
                    }
                  >
                    {editingPost ? t("Actions.update") : t("Actions.post")}
                  </Button>
                )}
              </div>
            </Dialog>
            <DeleteDialog
              open={deleteDialogState}
              onDialogClose={() => setDeleteDialogState(false)}
              onDelete={() => deletePost()}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
