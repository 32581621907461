import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './styles.scss'
import App from './App'
import { StateProvider } from './store'
import './i18n'
import { CircularProgress } from '@material-ui/core'
const app = (
  <StateProvider>
    <Suspense
      fallback={
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      }
    >
      <App />
    </Suspense>
  </StateProvider>
)
ReactDOM.render(app, document.getElementById('root'))
