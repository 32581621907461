/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { store } from "../../../../../store";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Divider,
  Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import condeoApi from "../../../../../common/condeoApi";
import notificationAction from "../../../../../store/actions/notificationAction";
import styles from "./TenantForm.module.scss";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Add, ArrowDropUpOutlined, Close } from "@material-ui/icons";
import AddressForm from "../../../../../components/Address/AddressForm/AddressForm";
import AddIcon from "@material-ui/icons/Add";
import emptyIcon from "../../../../../assets/Images/unit.png";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
  },
  grid: {
    flexGrow: 1,
  },
  container: {
    maxHeight: 450,
  },
  formContainer: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  textFieldMargin: {
    marginTop: "20px",
  },
  icon: {
    minWidth: "30px",
  },
  formControl: {
    width: "100%",
    marginTop: "-2px",
  },
  grayText: {
    color: "darkgrey",
  },
  rightGrid: {
    maxWidth: "50%",
    marginRight: "30px",
  },
}));
export default function TenantForm({ tenant, onEditModeSwitch, id }) {
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const editingData = JSON.parse(localStorage.getItem("editingData"));
  const [units, setUnits] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [additionalStreetState, setAdditionalStreetState] = useState(
    editingData?.address?.additionalAddressId
  );
  const { t } = useTranslation();
  const { user } = state;
  const { token } = state.auth;
  const [selectedLanguage, setSelectedLanguage] = useState(
    tenant?.preferedLanguage || i18n.language
  );
  const history = useHistory();
  const currentPath = history.location.pathname;

  useEffect(() => {
    fetchUnits();
  }, []);

  const fetchUnits = () => {
    condeoApi
      .fetchUnits(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setUnits(
                response.units.filter(
                  (unit) => unit.organizationId === user?.organization?.id
                )
              );
              setSelectedUnit(
                response.units
                  .filter(
                    (unit) => unit.organizationId === user?.organization?.id
                  )
                  .find((unit) => unit.id === tenant?.unitId) || null
              );
            }
          });
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "This field is Required";
    }
    if (!values.lastName) {
      errors.lastName = "This field is Required";
    }
    if (!values.email) {
      errors.email = "This field is Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.phone) {
      errors.phone = "This field is Required";
    } else if (values.phone.length > 15) {
      errors.phone = "This field must be no more than 15 characters long.";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: tenant?.firstName || "",
      lastName: tenant?.lastName || "",
      salutation: tenant?.salutation || "",
      remarks: tenant?.remarks || "",
      email: tenant?.email || "",
      phone: tenant?.phone || "",
      phoneProfessional: tenant?.phoneProfessional || "",
      phoneMobile: tenant?.phoneMobile || "",
      street: tenant?.address?.street || "",
      streetNumber: tenant?.address?.streetNumber || "",
      additionalStreetNumber: tenant?.address?.additionalStreetNumber || "",
      additionalStreet: tenant?.address?.additionalStreet || "",
      place: tenant?.address?.place || "",
      poBox: tenant?.address?.pobox || "",
      postCode: tenant?.address?.postcode || "",
      canton: tenant?.address?.canton || "",
      country: tenant?.address?.country || "",
    },
    validate,
    onSubmit: (values) => {
      updateTenant(values);
    },
  });
  async function updateTenant(values) {
    const model = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      unitId: selectedUnit?.id,
      salutation: values.salutation,
      remarks: values.remarks,
      organizationId: user?.organization.id,
      phone: values.phone,
      phoneProfessional: values.phoneProfessional,
      phoneMobile: values.phoneMobile,
      preferedLanguage: selectedLanguage,
      address: {
        street: values.street,
        additionalStreet: values.additionalStreet,
        streetNumber: values.streetNumber,
        additionalStreetNumber: values.additionalStreetNumber,
        place: values.place,
        poBox: values.poBox,
        canton: values.canton,
        postCode: values.postCode,
        country: values.country,
      },
    };

    if (!selectedUnit) {
      return dispatch(
        notificationAction({
          open: true,
          message: "Please select a unit",
        })
      );
    }

    let request;
    let message = "";
    if (id) {
      request = condeoApi.updateTenant(model, token, id);
      message = "updated";
    } else {
      request = condeoApi.createTenant(model, token);
      message = "added";
    }
    request
      .then((result) => {
        result.json().then((response) => {
          if (!response.success) {
            dispatch(
              notificationAction({
                open: true,
                message: response.message,
              })
            );
          } else if (result.status === 401) {
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.401"),
              })
            );
          } else {
            dispatch(
              notificationAction({
                open: true,
                message: `Tenant has been ${message} successfully`,
              })
            );
            if (id) {
              onEditModeSwitch();
            } else {
              history.push("/tenants");
            }
          }
        });
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  }
  const redirectBack = () => {
    history.goBack();
  };
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const handleAddUnit = (event) => {
    event.preventDefault();
    history.push("/units/new");
  };
  const getOptionLabel = (option) => `${option.label}`;

  const optionRenderer = (option, { selected }) => {
    return (
      <Fragment>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {getOptionLabel(option)}
      </Fragment>
    );
  };
  return (
    <Box p={2}>
      <Box className={styles.header}>
        <Typography variant="h6" color="textSecondary">
          {id ? "Edit" : "Add"} Tenant
        </Typography>
        {currentPath.split("/")[2] !== "new" && (
          <Tooltip title="Cancel">
            <IconButton onClick={onEditModeSwitch}>
              <Close />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Divider light />
      {units && units.length === 0 && (
        <Box height="35em">
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height="35em"
            textAlign="center"
          >
            <img src={emptyIcon} alt="No Units" style={{ maxWidth: "12em" }} />
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ margin: "1em" }}
            >
              Oops.. Looks like there are no Units to add this Tenant to. <br />
              To create a new Tenant you need to have a Unit created first!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddUnit}
              startIcon={<AddIcon />}
              style={{ width: "24em" }}
            >
              {t("Units.newUnit")}
            </Button>
          </Box>
        </Box>
      )}
      {units && units.length > 0 && (
        <Formik enableReinitialize={true}>
          <form onSubmit={formik.handleSubmit} className={classes.grid}>
            <Grid container>
              <Box width="100%" padding="1em" pt={"2em"}>
                <Box display="flex">
                  <Grid item xs={6} className={classes.rightGrid}>
                    <Box className={styles.textFieldContainer}>
                      <Box className={styles.textFieldMinHeight}>
                        <TextField
                          id="1"
                          label={t("Tenants.firstName")}
                          fullWidth
                          variant="outlined"
                          name="firstName"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <Box className={styles.error}>
                            {formik.errors.firstName}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box className={styles.textFieldMinHeight}>
                        <TextField
                          id="2"
                          label={t("Tenants.lastName")}
                          fullWidth
                          autoCapitalize="true"
                          variant="outlined"
                          name="lastName"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <Box className={styles.error}>
                            {formik.errors.lastName}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box className={styles.textFieldMinHeight}>
                        <TextField
                          id="3"
                          label={t("Tenants.salutation")}
                          fullWidth
                          variant="outlined"
                          name="salutation"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.salutation}
                        />
                        {formik.touched.salutation &&
                        formik.errors.salutation ? (
                          <Box className={styles.error}>
                            {formik.errors.salutation}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box className={styles.textFieldMinHeight}>
                        <TextField
                          id="4"
                          label={t("Tenants.remarks")}
                          fullWidth
                          variant="outlined"
                          name="remarks"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.remarks}
                        />
                        {formik.touched.remarks && formik.errors.remarks ? (
                          <Box className={styles.error}>
                            {formik.errors.remarks}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box className={styles.textFieldMinHeight}>
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Language
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                            label="Language"
                          >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="de">Deutsch(German)</MenuItem>
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            e.g. Tenant notifications langugage
                          </Typography>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ maxWidth: "50%" }}>
                    <Box className={styles.textFieldContainer}>
                      <Box className={styles.textFieldMinHeight}>
                        <TextField
                          id="5"
                          label={t("Tenants.email")}
                          fullWidth
                          variant="outlined"
                          name="email"
                          size="small"
                          disabled={!!tenant}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <Box className={styles.error}>
                            {formik.errors.email}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box className={styles.textFieldMinHeight}>
                        <TextField
                          id="6"
                          label={t("Tenants.phone")}
                          fullWidth
                          variant="outlined"
                          name="phone"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <Box className={styles.error}>
                            {formik.errors.phone}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box className={styles.textFieldMinHeight}>
                        <TextField
                          id="7"
                          label={t("Tenants.phoneProfessional")}
                          fullWidth
                          variant="outlined"
                          name="phoneProfessional"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneProfessional}
                        />
                        {formik.touched.phoneProfessional &&
                        formik.errors.phoneProfessional ? (
                          <Box className={styles.error}>
                            {formik.errors.phoneProfessional}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box className={styles.textFieldMinHeight}>
                        <TextField
                          id="8"
                          label={t("Tenants.phoneMobile")}
                          fullWidth
                          variant="outlined"
                          name="phoneMobile"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneMobile}
                        />
                        {formik.touched.phoneMobile &&
                        formik.errors.phoneMobile ? (
                          <Box className={styles.error}>
                            {formik.errors.phoneMobile}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Box>
                <Box className={styles.propertyContainer}>
                  {!units && <CircularProgress />}
                  {units && (
                    <Autocomplete
                      options={units}
                      getOptionLabel={(option) => option?.label}
                      className={styles.autoComplete}
                      onChange={(event, newUnit) => {
                        setSelectedUnit(newUnit);
                      }}
                      value={selectedUnit}
                      renderOption={optionRenderer}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a unit"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>
                {!tenant?.additionalAddressId && (
                  <Box width="100%" display="flex" alignItems="center" mb={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        setAdditionalStreetState(!additionalStreetState)
                      }
                      endIcon={
                        additionalStreetState ? (
                          <ArrowDropUpOutlined />
                        ) : (
                          <Add />
                        )
                      }
                    >
                      {additionalStreetState
                        ? "Hide Additional Address"
                        : t("Address.additionalAddress")}
                    </Button>
                  </Box>
                )}
                {additionalStreetState && (
                  <AddressForm formik={formik} id={id} />
                )}
              </Box>
            </Grid>
            <Box className={styles.gridFooter}>
              <Fragment>
                <Button startIcon={<ArrowBackIcon />} onClick={redirectBack}>
                  {t("Actions.back")}
                </Button>
                <Button
                  startIcon={<SaveIcon />}
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={styles.saveButton}
                >
                  {t("Actions.save")} {tenant && t("Actions.changes")}
                </Button>
              </Fragment>
            </Box>
          </form>
        </Formik>
      )}
    </Box>
  );
}
