import { Container, Paper } from "@material-ui/core";
import React from "react";
import PropertyForm from "../PropertyDetails/PropertyForm/PropertyForm";

export default function AddEditProperty({ property }) {
  return (
    <Container>
      <Paper>
        <PropertyForm property={property} />
      </Paper>
    </Container>
  );
}
