/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import condeoApi from "../../../common/condeoApi";
import notificationAction from "../../../store/actions/notificationAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, ListItemIcon, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArchiveIcon from "@material-ui/icons/Archive";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteDialog from "../../../common/Dialogs/DeleteDialog/DeleteDialog";
import styles from "./Properties.module.scss";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import {
  IconButton,
  Button,
  Table,
  TableHead,
  TablePagination,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import { store } from "../../../store";
import editingModeAction from "../../../store/actions/editingModeAction";
import selectedPropertyAction from "../../../store/actions/selectedPropertyAction";
import { useTranslation } from "react-i18next";
import emptyIcon from "../../../assets/Images/real-estate.png";
import { Add } from "@material-ui/icons";
function createData(
  id,
  label,
  description,
  street,
  additionalStreet,
  streetNumber,
  additionalStreetNumber,
  place,
  poBox,
  postCode,
  canton,
  country,
  addressId
) {
  return {
    id,
    label,
    description,
    street,
    additionalStreet,
    streetNumber,
    additionalStreetNumber,
    place,
    poBox,
    postCode,
    canton,
    country,
    addressId,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {},
  grid: {
    flexGrow: 1,
  },
  container: {
    maxHeight: 440,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  textFieldMargin: {
    marginTop: "20px",
  },
  icon: {
    minWidth: "35px",
  },
  spinner: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    justifyContent: "center",
  },
  actionsColumn: {
    width: "140px",
    paddingLeft: "30px",
  },
}));

export default function Properties(props) {
  const token = localStorage.getItem("auth_token");
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [properties, setProperties] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogState, setDialogState] = React.useState(false);
  const [deletingProperty, setDeletingProperty] = React.useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const { dispatch } = useContext(store);
  const { t } = useTranslation();

  useEffect(() => {
    fetchProperties();
  }, []);
  const fetchProperties = () => {
    condeoApi
      .fetchProperties(token)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response) {
              setProperties(
                response.properties.filter(
                  (property) =>
                    property?.organizationId === user?.organization?.id
                )
              );
            }
          });
        } else {
          console.log("Something went wrong while fetching properties!");
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.general"),
            })
          );
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };

  const rows = properties
    ? properties.map((property) => {
        return createData(
          property.id,
          property.label,
          property.description,
          property.address.street,
          property.address.additionalStreet,
          property.address.streetNumber,
          property.address.additionalStreetNumber,
          property.address.place,
          property.address.poBox,
          property.address.postCode,
          property.address.canton,
          property.address.country,
          property.address.id
        );
      })
    : [];
  const columns = [
    {
      key: 1,
      id: "label",
      label: `${t("AllProperties.label")}`,
      minWidth: 120,
      maxWidth: 300,
      format: (value) => value.toLocaleString(),
    },
    {
      key: 2,
      id: "description",
      label: `${t("AllProperties.description")}`,
      minWidth: 150,
      width: 300,
      format: (value) => value.toLocaleString(),
    },
    {
      key: 3,
      id: "street",
      label: `${t("Address.street")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString(),
    },
    {
      key: 4,
      id: "streetNumber",
      label: `${t("Address.streetNumber")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString(),
    },
    {
      key: 5,
      id: "place",
      label: `${t("Address.place")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString(),
    },
    {
      key: 6,
      id: "canton",
      label: `${t("Address.canton")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString(),
    },
    {
      key: 7,
      id: "country",
      label: `${t("Address.country")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString(),
    },
    {
      key: 8,
      id: "actions",
      label: `${t("Actions.1")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString(),
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
    setDialogState(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleAddProperty = (event) => {
    history.push("/properties/new");
  };
  const handleDeleteMenu = (event, property) => {
    setAnchorEl(event.currentTarget);
    setDeletingProperty(property);
  };
  const handleDeleteDialog = () => {
    setDialogState(true);
    setAnchorEl(null);
  };
  const handlePermanentDelete = () => {
    condeoApi
      .deleteProperty(token, deletingProperty.id)
      .then((result) => {
        if (result.ok) {
          result.json().then((response) => {
            if (response.success) {
              setDialogState(false);
              fetchProperties();
              dispatch(
                notificationAction({
                  open: true,
                  message: t("Delete.property"),
                })
              );
            }
          });
        } else if (result.status === 401) {
          dispatch(
            notificationAction({
              open: true,
              message: t("Errors.401"),
            })
          );
        } else if (result.status === 409) {
          if (result.statusText === "Conflict") {
            setDialogState(false);
            dispatch(
              notificationAction({
                open: true,
                message: t("Errors.constraint"),
              })
            );
          }
        }
      })
      .catch((err) => {
        console.log("Could not communicate with the server!", err.message);
        dispatch(
          notificationAction({
            open: true,
            message: t("Errors.general"),
          })
        );
      });
  };
  const navigateToPropertyDetails = (property) => {
    history.push(`/properties/${property.id}/details`);
    const model = {
      id: property.id,
      label: property.label,
      description: property.description,
      address: {
        street: property.street,
        additionalStreet: property.additionalStreet,
        streetNumber: property.streetNumber,
        additionalStreetNumber: property.additionalStreetNumber,
        place: property.place,
        poBox: property.poBox,
        postCode: property.postCode,
        canton: property.canton,
        country: property.country,
        id: property.id,
      },
    };
    dispatch(
      selectedPropertyAction({
        selectedProperty: model,
      })
    );
    dispatch(
      editingModeAction({
        editingMode: true,
      })
    );
  };
  return (
    <>
      <div className={styles.allPropertiesHeader}>
        <Typography variant="h6"> {t("AllProperties.1")}</Typography>
        {(user?.admin && properties && properties?.length) > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProperty}
            startIcon={<AddIcon />}
          >
            {t("AllProperties.newProperty")}
          </Button>
        )}
      </div>
      {properties && properties.length > 0 && (
        <Paper className={classes.root}>
          {!properties && <LinearProgress />}
          <DeleteDialog
            open={dialogState}
            onDialogClose={handleClose}
            onDelete={handlePermanentDelete}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.key}
                      align={column.align}
                      style={{
                        width: column.width,
                        paddingLeft: column.paddingLeft,
                        minWidth: column.minWidth,
                        maxWidth: "300px !important",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <React.Fragment key={`col-${column.key}`}>
                              {column.id === "actions" ? (
                                <TableCell>
                                  <IconButton
                                    id="property-details"
                                    onClick={() =>
                                      navigateToPropertyDetails(row)
                                    }
                                  >
                                    <MenuIcon />
                                  </IconButton>
                                  <IconButton
                                    id="delete-property-icon"
                                    onClick={(event) =>
                                      handleDeleteMenu(event, row)
                                    }
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </TableCell>
                              ) : (
                                <TableCell
                                  align={column.align}
                                  className={styles.tableCell}
                                >
                                  {value}
                                </TableCell>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        elevation={1}
                      >
                        <MenuItem onClick={handleClose}>
                          <ListItemIcon className={classes.icon}>
                            <ArchiveIcon fontSize="small" />
                          </ListItemIcon>
                          {t("Delete.archive")}
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteDialog(row)}>
                          <ListItemIcon
                            className={classes.icon}
                            id="permanent-delete"
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </ListItemIcon>
                          {t("Delete.permanentDelete")}
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          <ListItemIcon className={classes.icon}>
                            <CloseIcon fontSize="small" />
                          </ListItemIcon>
                          {t("Actions.cancel")}
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {properties && properties.length === 0 && (
        <Box className={styles.emptyStateContainer}>
          <img
            src={emptyIcon}
            alt="No Properties"
            className={styles.emptyStateImg}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            className={styles.emptyStateText}
          >
            There are currently no Properties to display.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProperty}
            endIcon={<Add fontSize="large" />}
            className={styles.emptyStateBtn}
          >
            {t("AllProperties.newProperty")}
          </Button>
        </Box>
      )}
    </>
  );
}
